/* global google */

import React, {useEffect, useState} from "react";
import {PositionActivity} from "../../../../types/beemove-intervention";
import {InterventionPosition} from "../../../../types/intervention-simulator";
import {isIterable, isNullOrUndefined} from "../../toolbox";
import {useBlueprintManager} from "./providers/blueprint";
import {useMasterdataManager} from "./providers/masterdata";


const SEQUENCE_NUMBER_MIN = 1;

const DEFAULT_STROKE_COLOR = '#A6A6A6';
const DEFAULT_STROKE_WEIGHT = 10;
const DEFAULT_STROKE_RATIO = 0.5;

export interface TrailState {
    lines: google.maps.PolylineOptions[],
    positions: InterventionPosition[],
    selectedPosition: InterventionPosition,

    // --- functions --- //
    setSelectedPosition: React.Dispatch<InterventionPosition>,

    selectPositionBySequenceNumber(sequenceNumber: InterventionPosition['sequenceNumber']): void,

    selectNextPosition(): void,

    selectPreviousPosition(): void,

    updateTrail(): void,

    resetPositions(): void
}


// Provider hook that creates auth object and handles state
export function useProvideTrailManager(): TrailState {
    const {activities} = useMasterdataManager();
    const {selectedBlueprint} = useBlueprintManager();

    // --- Local --- //
    const [positions, setPositions] = useState<InterventionPosition[]>([]);
    const [selectedPosition, setSelectedPosition] = useState<InterventionPosition | null>(null);
    useEffect(() => {
        setSelectedPosition(null);
    }, [positions, selectedBlueprint]);

    // --- Data for the map --- //
    const [lines, setLines] = useState([]);

    const activityComparator = (pa1: PositionActivity, pa2: PositionActivity) => {
        if (isNullOrUndefined(pa1.activityId)) {
            return -1;
        } else if (isNullOrUndefined(pa2.activityId)) {
            return 1;
        } else {
            const a1 = activities.find(activity => pa1.activityId === activity.id);
            const a2 = activities.find(activity => pa2.activityId === activity.id);
            return a1.name.localeCompare(a2.name);
        }
    };

    function updateTrail() {
        if (typeof google !== 'object' || typeof google.maps !== 'object') return;
        if (positions.length < 2) {
            setLines([]);
            return;
        }
        const polylineOptionsArray: google.maps.PolylineOptions[] = [];

        // Vars init
        const polylineOptionsMap: Map<string, google.maps.PolylineOptions> = new Map(); // <Color, PolylineOptions>
        const colorsOld: Set<string> = new Set();

        // Start
        // const a = positions[0]
        // const b = positions[positions.length - 1];
        // onStart(new LatLng(a.getLatitude(), a.getLongitude()));

        const activityColorMap = new Map();
        activities.forEach(activity => {
            activityColorMap.set(activity.id, activity.activityCategory.color)
        });

        // Iterate over the positions
        positions.forEach(position => {
            if (isNullOrUndefined(position.latitude) || isNullOrUndefined(position.longitude)) return;
            const colorsNew: Set<string> = new Set();
            const newLatLng = new google.maps.LatLng(position.latitude, position.longitude);

            if (isIterable(position.activities)) {
                if (position.activities.length > 2) {
                    colorsNew.add('#000000');
                    colorsNew.add('#FFFFFF')
                } else {
                    position.activities
                        .sort(activityComparator)
                        .forEach(({
                            activityId, option, // FIXME: status
                        }) => {
                            colorsNew.add(// FIXME status?.color || // Color from status if any
                                activityColorMap.get(activityId) || // Color from activity category
                                DEFAULT_STROKE_COLOR // Color for 'inactive status'
                            );
                        });
                }
            } else {
                colorsNew.add(DEFAULT_STROKE_COLOR);
            }

            // Check if all should be terminated
            let shouldTerminateAllLines = position.sequenceNumber === positions.length || colorsOld.size !== colorsNew.size;
            colorsOld.forEach(color => shouldTerminateAllLines || !colorsNew.has(color));

            // Terminate discontinued lines
            colorsOld.forEach(color => {
                const polylineOptions = polylineOptionsMap.get(color);
                // @ts-ignore
                polylineOptions.path.push(newLatLng);

                // If discontinued, add to pool and remove from the map
                if (shouldTerminateAllLines) {
                    console.debug(`Color ${color} stopped @ ${position.sequenceNumber}`, colorsOld, colorsNew);
                    polylineOptionsArray.push(polylineOptions);
                    polylineOptionsMap.delete(color);
                }
            });

            // Start or continue a line
            let width = DEFAULT_STROKE_WEIGHT;
            colorsNew.forEach(color => {
                if (shouldTerminateAllLines || !colorsOld.has(color)) {
                    const polylineOptions = {
                        path: [newLatLng], strokeColor: color, strokeWeight: width
                    };
                    polylineOptionsMap.set(color, polylineOptions);
                    console.debug(`Color ${color} started @ ${position.sequenceNumber}`, colorsOld, colorsNew);
                }
                width *= DEFAULT_STROKE_RATIO;
            });

            // Update colors
            colorsOld.clear();
            colorsNew.forEach(c => colorsOld.add(c));
        });

        polylineOptionsArray.sort((po1, po2) => po2.strokeWeight - po1.strokeWeight);

        // Update trail
        setLines(polylineOptionsArray);
    }

    useEffect(resetPositions, [selectedBlueprint]);
    useEffect(updateTrail, [positions]);

    function selectPositionBySequenceNumber(sequenceNumber: InterventionPosition['sequenceNumber']) {
        if (isIterable(positions) && sequenceNumber >= SEQUENCE_NUMBER_MIN) {
            setSelectedPosition(positions.find(p => p.sequenceNumber === sequenceNumber));
        }
    }

    function selectNextPosition() {
        selectPositionBySequenceNumber(Math.min(positions.length, selectedPosition.sequenceNumber + 1));
    }

    function selectPreviousPosition() {
        selectPositionBySequenceNumber(Math.max(SEQUENCE_NUMBER_MIN, selectedPosition.sequenceNumber - 1));
    }

    function resetPositions() {
        // TODO: Warn of data loss if any modifications has been made ?
        // TODO: Nice to have, stack of modifications ?
        if (isNullOrUndefined(selectedBlueprint) || !isIterable(selectedBlueprint.positions)) {
            setPositions([]);
        } else {
            setPositions(JSON.parse(JSON.stringify(selectedBlueprint.positions)));
        }
    }

    return {
        lines,
        positions,
        selectedPosition,

        // --- functions --- //
        setSelectedPosition,
        selectPositionBySequenceNumber,
        selectNextPosition,
        selectPreviousPosition,
        updateTrail,
        resetPositions
    }
}
