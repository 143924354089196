import React, {createContext, useContext} from "react";
import {SimulatorState, useProvideSimulatorManager} from "../simulator";

const DataContext = createContext<SimulatorState>(undefined);

export function ProvideSimulatorManager({children}) {
    const dataManager = useProvideSimulatorManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSimulatorManager = () => {
    return useContext(DataContext);
};
