import React, {createContext, useContext} from "react";
import {BlueprintState, useProvideBlueprintManager} from "../blueprint";

const DataContext = createContext<BlueprintState>(undefined);

export function ProvideBlueprintManager({children}) {
    const dataManager = useProvideBlueprintManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useBlueprintManager = () => {
    return useContext(DataContext);
};
