import React, {useEffect, useState} from "react";
import {CenterCascaded, Equipment, Operator, Vehicle} from "../../../../types/beemove-masterdata";
import {InterventionConfig} from "../../../../types/intervention-simulator";
import {SimulatorAPI} from "../../../api/intervention/simulator";
import {INTERVENTION_CONFIG_ID} from "../../../constants/storage/keys";
import {isIterable, isNullOrUndefined} from "../../toolbox";
import {useAuth} from "../providers/auth";
import {useOrganisationManager} from "../providers/organisation";
import {useStateWithLocalStorage} from "../storage";
import {useMasterdataManager} from "./providers/masterdata";

const DEFAULT_CONFIG_ID = -1;

export interface ConfigState {
    // --- Local --- //
    configs: InterventionConfig[],
    availableOperators: Operator[],

    // --- Config --- //
    selectedConfig: InterventionConfig,
    selectedCenter: CenterCascaded,
    selectedVehicle: Vehicle,
    selectedEquipment: Equipment[],
    selectedMainOperator: Operator,
    selectedCoOperator: Operator,
    selectedOperators: Operator[],

    // --- Setter --- //
    setSelectedConfig: React.Dispatch<InterventionConfig>,
    setSelectedCenter: React.Dispatch<CenterCascaded>,
    setSelectedVehicle: React.Dispatch<Vehicle>,
    setSelectedEquipment: React.Dispatch<Equipment[]>,
    setSelectedMainOperator: React.Dispatch<Operator>,
    setSelectedCoOperator: React.Dispatch<Operator>,
    setSelectedOperators: React.Dispatch<Operator[]>,

    // --- Functions --- //
    fetch(): Promise<void>,

    createConfig(configName: InterventionConfig['name']): Promise<InterventionConfig>,

    saveConfig(): Promise<boolean>,

    deleteConfig(configId: InterventionConfig['id']): Promise<boolean>
}

const defaultConfig: Omit<InterventionConfig, 'name'> = {
    'centerId': null,
    'mainOperatorId': null,
    'secondaryOperatorId': null,
    'operatorIds': [],
    'vehicle': null,
    'equipments': [],
};

// Provider hook that creates auth object and handles state
export function useProvideConfigManager(): ConfigState {
    const auth = useAuth();
    const {selectedOrganisation} = useOrganisationManager();
    const {centers} = useMasterdataManager();

    // --- Local --- //
    const [availableOperators, _setAvailableOperators] = useState<Operator[]>([]);

    // --- Fetched data --- //
    const [configs, _setConfigs] = useState<InterventionConfig[]>([]);

    // MasterData - Config
    const [_selectedConfigId, _setSelectedConfigId] = useStateWithLocalStorage<InterventionConfig['id']>(INTERVENTION_CONFIG_ID, null);
    const [selectedConfig, setSelectedConfig] = useState<InterventionConfig>(() => {
        return configs && configs.length && configs[_selectedConfigId];
    });

    // Config
    const [selectedCenter, setSelectedCenter] = useState<CenterCascaded>(null);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>(null);
    const [selectedMainOperator, setSelectedMainOperator] = useState<Operator>(null);
    const [selectedCoOperator, setSelectedCoOperator] = useState<Operator>(null);
    const [selectedOperators, setSelectedOperators] = useState<Operator[]>([]);
    const [selectedEquipment, setSelectedEquipment] = useState<Equipment[]>([]);

    function resetSelection(resetCenter: boolean) {
        if (resetCenter) {
            setSelectedCenter(null);
        }
        setSelectedVehicle(null);
        setSelectedMainOperator(null);
        setSelectedCoOperator(null);
        setSelectedOperators([]);
        setSelectedEquipment([]);
    }

    // Boot load
    useEffect(() => {
        fetch();
    }, [selectedOrganisation]);

    useEffect(() => {
        if (!isIterable(configs)) return;
        console.debug("Fetch: Updating configs…", configs);
        setSelectedConfig(configs.find(config => config.id === _selectedConfigId));
    }, [configs]);

    // Effects
    useEffect(() => {
        if (!isNullOrUndefined(selectedConfig) && !isIterable(centers)) return;
        console.debug("Fetch: Updating centers…", centers);
        setSelectedCenter(centers.find(center => center.id === selectedCenter?.id));
    }, [centers]);

    // Reload config on change
    useEffect(() => {
        console.debug("Fetch: Updating selected config…", selectedConfig);
        if (isNullOrUndefined(selectedConfig)) {
            resetSelection(true);
        } else {
            console.debug("Loading config…", selectedConfig);
            setSelectedCenter(centers.find(center => center.id === selectedConfig.centerId));
        }
        _setSelectedConfigId(selectedConfig?.id);
    }, [selectedConfig]);

    useEffect(() => {
        console.debug("Fetch: Updating selected center…", selectedCenter);
        if (!isNullOrUndefined(selectedConfig) && !isNullOrUndefined(selectedCenter)) {
            const {
                operators, vehicles, equipment
            } = selectedCenter;
            const operatorsConfigIds = new Set(selectedConfig.operatorIds.filter(operatorId => (operatorId !== selectedConfig.mainOperatorId && operatorId !== selectedConfig.secondaryOperatorId)));
            const equipmentConfigIds = new Set(selectedConfig.equipments.map(e => e.id));

            // Operators
            setSelectedMainOperator(operators.find(o => o.id === selectedConfig.mainOperatorId));
            setSelectedCoOperator(operators.find(o => o.id === selectedConfig.secondaryOperatorId));
            setSelectedOperators(operators.filter(o => operatorsConfigIds.has(o.id)));

            // Vehicle + Equipment
            setSelectedVehicle(vehicles.find(v => v.id === selectedConfig.vehicle?.id));
            setSelectedEquipment(equipment.filter(e => equipmentConfigIds.has(e.id)));
        } else {
            resetSelection(false);
        }
    }, [selectedCenter]);

    useEffect(() => {
        console.debug("Fetch: Updating available operators…");
        if (!isNullOrUndefined(selectedCenter)) {
            const hiddenIds = new Set();
            if (!isNullOrUndefined(selectedMainOperator)) {
                hiddenIds.add(selectedMainOperator.id);
                console.debug("Fetch: Main operator shadowed…", selectedMainOperator);
            }
            if (!isNullOrUndefined(selectedCoOperator)) {
                hiddenIds.add(selectedCoOperator.id);
                console.debug("Fetch: Co operator shadowed…", selectedCoOperator);
            }
            if (isIterable(selectedOperators)) {
                selectedOperators.forEach(operator => hiddenIds.add(operator.id));
                console.debug("Fetch: Helper operators shadowed…", selectedOperators);
            }

            // Compute operators
            _setAvailableOperators(selectedCenter.operators.filter(op => !hiddenIds.has(op.id)));
        }
    }, [selectedCenter, selectedMainOperator, selectedCoOperator, selectedOperators]);

    /**
     * Fetches intervention configurations from the simulator service.
     */
    async function fetch(): Promise<void> {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        await SimulatorAPI.getConfigs(auth, selectedOrganisation.id)
            .then(_setConfigs)
            .catch(e => {
                // TODO
            });
    }

    function getConfig(): InterventionConfig {
        const operatorIds = (isIterable(selectedOperators) && selectedOperators.map(o => o.id)) || [];
        if (!isNullOrUndefined(selectedMainOperator)) {
            operatorIds.push(selectedMainOperator.id);
        }
        if (!isNullOrUndefined(selectedCoOperator)) {
            operatorIds.push(selectedCoOperator.id);
        }
        return Object.assign({}, selectedConfig, getSelection(), {'operatorIds': operatorIds});
    }

    async function createConfig(configName: InterventionConfig['name']): Promise<InterventionConfig> {
        if (configs.find(c => c.id === DEFAULT_CONFIG_ID)) {
            // TODO: Throw Error 'already new config, save first'
            return;
        }

        // Create the new config
        const config: InterventionConfig = {
            ...defaultConfig, id: DEFAULT_CONFIG_ID, name: configName, centerId: selectedCenter.id
        };
        fetch().then(() => {
            _setConfigs([...configs, config]);
            setSelectedConfig(config);
        });

        return config;
    }

    /**
     * Saves current intervention configuration to the simulator service.
     * @return {Promise<*>}
     */
    async function saveConfig(): Promise<boolean> {
        if (isNullOrUndefined(selectedOrganisation?.id)) return false;
        const config = getConfig();
        let callback;
        if (config?.id === DEFAULT_CONFIG_ID) {
            callback = SimulatorAPI.createConfig;
            delete config.id;
        } else {
            callback = SimulatorAPI.updateConfig;
        }
        return await callback(auth, selectedOrganisation.id, config).then(fetch);
    }

    /**
     * Deletes the currently selected intervention configuration
     * @return {Promise<*>}
     */
    async function deleteConfig(configId: InterventionConfig['id']): Promise<boolean> {
        if (isNullOrUndefined(selectedOrganisation?.id)) return false;
        if (configId !== DEFAULT_CONFIG_ID) {
            await SimulatorAPI.deleteConfig(auth, selectedOrganisation.id, configId);
        }
        fetch().then(() => {
            resetSelection(true);
            setSelectedConfig(null);
        });
        return true;
    }

    function getOperator(id: Operator['id']) {
        return Array.isArray(selectedCenter.operators) && selectedCenter.operators.find(o => o.id === id);
    }

    function getSelection(): Omit<InterventionConfig, 'name'> | null {
        if (isNullOrUndefined(selectedCenter)) {
            return null;
        }

        const operatorIds = (isIterable(selectedOperators) && selectedOperators.map(o => o.id)) || [];
        const vehicle = selectedVehicle && Object.assign({}, {
            id: selectedVehicle.id,
            measurandIds: isIterable(selectedVehicle.measurands) ? selectedVehicle.measurands.map(m => m.id) : []
        });
        const equipment = isIterable(selectedEquipment) ? selectedEquipment.map(e => Object.assign({}, {
            id: e.id, measurandIds: isIterable(e.measurands) ? e.measurands.map(m => m.id) : []
        })) : [];

        return {
            'centerId': selectedCenter?.id,
            'mainOperatorId': selectedMainOperator?.id,
            'secondaryOperatorId': selectedCoOperator?.id,
            'operatorIds': operatorIds,
            'vehicle': vehicle,
            'equipments': equipment,
        };
    }

    return {
        // --- Local --- //
        configs,
        availableOperators,

        // --- Config --- //
        selectedConfig,
        selectedCenter,
        selectedVehicle,
        selectedEquipment,
        selectedMainOperator,
        selectedCoOperator,
        selectedOperators,

        // --- Setter --- //
        setSelectedConfig,
        setSelectedCenter,
        setSelectedVehicle,
        setSelectedEquipment,
        setSelectedMainOperator,
        setSelectedCoOperator,
        setSelectedOperators,

        // --- Functions --- //
        fetch,
        createConfig,
        saveConfig,
        deleteConfig
    }
}
