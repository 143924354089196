import {Box} from "@mui/material";
import React from 'react';
import {useTranslation} from "react-i18next";
import {MUITableStyle} from "../../../../../../styles/mui";
import {InterventionSchedule} from "../../../../../../types/intervention-simulator";
import {useSimulatorManager} from "../../../../../utils/hooks/data/providers/simulator";
import {BasicModal, BasicModalContent, BasicModalHeader, Props as ModalProps} from "../../../../modal/basic-modal";
import {PropertyTable} from "../../../../tables/property-table";

interface Props extends ModalProps {
    clickedId: InterventionSchedule['id']
}

export function ScheduleModal({setShowModal, clickedId, ...props}: Props) {
    const {t} = useTranslation();
    const {scheduledInterventions} = useSimulatorManager();
    const scheduledIntervention = scheduledInterventions.find(i => i.id === clickedId);
    const ignored = new Set(['id']);
    const translations = {
        'cron': t('cron_table_header_cron'),
        'runningType': t('cron_table_header_running_type'),
        'positionFixedRateMs': t('cron_table_header_position_fixed_rate_ms'),
        'enable': t('cron_table_header_enable'),
        'interventionId': t('cron_table_header_intervention_id'),
    };

    return (<BasicModal setShowModal={setShowModal}>
        <BasicModalHeader>
            <h1>{clickedId}</h1>
        </BasicModalHeader>
        <BasicModalContent>
            <Box sx={MUITableStyle}>
                <PropertyTable
                    translations={translations}
                    ignored={ignored}
                    item={scheduledIntervention}
                />
            </Box>
        </BasicModalContent>
    </BasicModal>);
}
