import {useCallback, useEffect} from "react";
import {Activity, CenterCascaded, Consumable, Status} from "../../../../types/beemove-masterdata";
import {BeemoveMasterdataAPI} from '../../../api/beemove/masterdata';
import {
    MASTERDATA_ACTIVITIES, MASTERDATA_CENTERS, MASTERDATA_CONSUMABLES, MASTERDATA_STATUSES
} from "../../../constants/storage/keys";
import {isNullOrUndefined} from "../../toolbox";
import {useAuth} from "../providers/auth";
import {useOrganisationManager} from "../providers/organisation";
import {useStateWithLocalStorage} from "../storage";

export interface BeemoveMasterdataState {
    activities: Activity[],
    centers: CenterCascaded[],
    consumables: Consumable[],
    statuses: Status[]

    fetch(): void,
}

// Provider hook that creates auth object and handles state
export function useProvideMasterdataManager(): BeemoveMasterdataState {
    const auth = useAuth();
    const {selectedOrganisation} = useOrganisationManager();

    // --- Masterdata --- //
    const [activities, setActivities] = useStateWithLocalStorage<Activity[]>(MASTERDATA_ACTIVITIES, []);
    const [centers, setCenters] = useStateWithLocalStorage<CenterCascaded[]>(MASTERDATA_CENTERS, []);
    const [consumables, setConsumables] = useStateWithLocalStorage<Consumable[]>(MASTERDATA_CONSUMABLES, []);
    const [statuses, setStatuses] = useStateWithLocalStorage<Status[]>(MASTERDATA_STATUSES, []);
    // TODO : Procedures ? Automated tests ?

    const fetch = useCallback(() => {
        if (isNullOrUndefined(selectedOrganisation?.id)) return false;
        BeemoveMasterdataAPI.getActivities(auth, selectedOrganisation.id).then(setActivities).catch(e => {
            //TODO
        });
        BeemoveMasterdataAPI.getCenters(auth, selectedOrganisation.id).then(setCenters).catch(e => {
            //TODO
        });
        BeemoveMasterdataAPI.getConsumables(auth, selectedOrganisation.id).then(setConsumables).catch(e => {
            //TODO
        });
        BeemoveMasterdataAPI.getStatuses(auth, selectedOrganisation.id).then(setStatuses).catch(e => {
            // TODO
        });
    }, [auth, selectedOrganisation]);

    // --- Boot load --- //
    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        fetch, activities, centers, consumables, statuses
    }
}
