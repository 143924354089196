import {Box} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MUITableStyle} from "../../../../../styles/mui";
import {ScheduledInterventionDtoExample} from "../../../../constants/examples/scheduledInterventionDtoExample";
import {useSimulatorManager} from "../../../../utils/hooks/data/providers/simulator";
import {removeFromArray} from "../../../../utils/toolbox";
import {Loading} from "../../../loading/loading";
import {OkCancelModal} from "../../../modal/ok-cancel-modal";
import {DenseTable} from "../../../tables/dense-table";
import {ScheduleModal} from "./modals/schedule-modal";

// Style
import './style.scss';

export function SchedulesTable() {
    const {t} = useTranslation();
    const {scheduledInterventions, updateSchedule, deleteSchedule} = useSimulatorManager();

    const [clickedId, setClickedId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [rows, setRows] = useState(null);
    const headers = Object.keys(ScheduledInterventionDtoExample);
    const ignored = removeFromArray(Object.keys(ScheduledInterventionDtoExample), 'id');

    useEffect(() => {
        setRows(scheduledInterventions);
    }, [scheduledInterventions]);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);

    function _openModal(id) {
        setSelectedScheduleId(id);
        setShowConfirmModal(true);
    }

    function _onOk() {
        deleteSchedule(selectedScheduleId);
        setShowConfirmModal(false);
    }

    function _onCancel() {
        setShowConfirmModal(false);
    }

    function _pauseSchedule(schedule) {
        updateSchedule(Object.assign({}, {...schedule}, {
            enable: false
        }));
    }

    function _resumeSchedule(schedule) {
        updateSchedule(Object.assign({}, {...schedule}, {
            enable: true
        }));
    }

    return Array.isArray(rows) ? (<Box sx={MUITableStyle} className={'half-height'}>
        <DenseTable
            headers={headers}
            ignored={ignored}
            translations={{'id': t('intervention_table_header_id')}}
            items={rows}
            onRowClick={intervention => {
                setClickedId(intervention.id);
                setShowModal(true);
            }}
            actions={(schedule) => [{
                'iconName': schedule.enable ? 'eye' : 'eyeOff',
                'callback': () => schedule.enable ? _pauseSchedule(schedule) : _resumeSchedule(schedule)
            }, {
                'iconName': 'delete', 'callback': () => _openModal(schedule.id)
            }]}
            itemCountPerPage={5}
        />
        {showModal && <ScheduleModal setShowModal={setShowModal} clickedId={clickedId}/>}
        {showConfirmModal && (<OkCancelModal
            title={t('modal_confirm_title')}
            content={t('modal_confirm_body')}
            onOk={_onOk}
            onCancel={_onCancel}
        />)}
    </Box>) : <Loading/>;
}
