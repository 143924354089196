import {FormControl, InputLabel, MenuItem, Select as MUISelect} from "@mui/material";
import PropTypes from "prop-types";
import React from 'react';
import {useTranslation} from "react-i18next";
import {hasData} from "../../utils/toolbox";

export function Select({label, data, selectedData, setSelectedData}) {
    const {t} = useTranslation();

    function handleChange(e) {
        setSelectedData(data.find(item => item.id === e.target.value));
    }

    return (<FormControl className={'flex-container expand'}>
        {label && (<InputLabel htmlFor={label} shrink={true}>
            {label}
        </InputLabel>)}
        <MUISelect
            id={label}
            displayEmpty={true}
            renderValue={id => hasData(data) ? (selectedData?.name ?? t('none')) : t('nothing_found')}
            onChange={handleChange}
            variant={'standard'}
            value={selectedData?.id || ''}
            MenuProps={{
                style: {
                    zIndex: 9999999999
                }
            }}
        >
            <MenuItem key={-1} value={null}>{t('none')}</MenuItem>
            {hasData(data) && data.map((option, index) => {
                return (<MenuItem key={index} value={option.id}>{option.name}</MenuItem>)
            })}
        </MUISelect>
    </FormControl>)
}

Select.propTypes = {
    'data': PropTypes.arrayOf(PropTypes.shape({
        'id': PropTypes.number.isRequired, 'name': PropTypes.string.isRequired
    })).isRequired,
    'selectedData': PropTypes.object || PropTypes.number,
    'setSelectedData': PropTypes.func.isRequired,
    'label': PropTypes.string,
}
