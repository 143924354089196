import {Upload} from '@mui/icons-material';
import {Button as MuiButton} from '@mui/material';
import PropTypes from "prop-types";
import React, {useRef} from 'react';

// @ts-ignore
import {default as BoschungColors} from '../../../styles/_colors.scss';

export function UploadButton({id, setFile}) {
    const inputRef = useRef(null);

    function handleOnChange({target}) {
        setFile(target.files[0]);
    }

    return (<div className={'flex-container center'}>
        <input
            ref={inputRef}
            accept='.gpx'
            hidden
            id={id}
            type='file'
            onChange={handleOnChange}
            style={{display: 'none'}}
        />
        <label htmlFor={id}>
            <MuiButton
                sx={{
                    height: '8em',
                    width: '8em',
                    color: 'black',
                    backgroundColor: 'white',
                    borderColor: BoschungColors.boschungMainColor
                }}
                variant='outlined'
                component='span'
                className={'card-mini'}
            >
                <Upload/>
            </MuiButton>
        </label>
    </div>);
}

UploadButton.propTypes = {
    id: PropTypes.string.isRequired, setFile: PropTypes.func.isRequired
}
