import React, {createContext, PropsWithChildren, useContext} from "react";
import {TrailState, useProvideTrailManager} from "../trail";

const DataContext = createContext<TrailState>(undefined);

export function ProvideTrailManager({children}: PropsWithChildren) {
    const dataManager = useProvideTrailManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useTrailManager = () => {
    return useContext(DataContext);
}
