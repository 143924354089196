import {useEffect, useState} from "react";
import {Intervention} from "../../../../types/beemove-intervention";
import {Blueprint, InterventionSchedule} from "../../../../types/intervention-simulator";
import {SimulatorAPI} from '../../../api/intervention/simulator';
import {isNullOrUndefined} from "../../toolbox";
import {useAuth} from "../providers/auth";
import {useOrganisationManager} from "../providers/organisation";

export interface SimulatorState {
    interventions: Intervention[],
    scheduledInterventions: InterventionSchedule[],

    fetch(): void,

    instantIntervention(
        blueprintId: Blueprint['id'],
        runningType: InterventionSchedule['runningType'],
        positionFixedRate: InterventionSchedule['positionFixedRateMs'],
        startDateTime: Intervention['startDateTime']
    ): Promise<void>;

    startIntervention(
        blueprintId: Blueprint['id'],
        runningType: InterventionSchedule['runningType'],
        positionFixedRate: InterventionSchedule['positionFixedRateMs'],
        startDateTime: Intervention['startDateTime']
    ): Promise<void>;

    closeIntervention(interventionId: Intervention['id']): Promise<void>,

    closeInterventions(): Promise<void>,

    scheduleIntervention(scheduledInterventionDto: InterventionSchedule): Promise<void>,

    updateSchedule(scheduledInterventionDto: InterventionSchedule): Promise<void>;

    deleteSchedule(scheduleId: InterventionSchedule['id']): Promise<void>
}

// Provider hook that creates auth object and handles state
export function useProvideSimulatorManager(): SimulatorState {
    const auth = useAuth();
    const {selectedOrganisation} = useOrganisationManager();

    // --- SimulatorAPI data --- //
    const [interventions, _setInterventions] = useState<Intervention[]>([]);
    const [scheduledInterventions, _setScheduledInterventions] = useState<InterventionSchedule[]>([]);

    function fetch() {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.getInterventions(auth, selectedOrganisation.id).then(_setInterventions).catch(e => {
            //TODO
        });
        SimulatorAPI.getScheduledInterventions(auth, selectedOrganisation.id).then(_setScheduledInterventions).catch(e => {
            // TODO
        });
    }

    async function instantIntervention(
        blueprintId: Blueprint['id'],
        runningType: InterventionSchedule['runningType'],
        positionFixedRate: InterventionSchedule['positionFixedRateMs'],
        startDateTime: Intervention['startDateTime']
    ) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        return SimulatorAPI.instantIntervention(auth, selectedOrganisation.id, blueprintId, runningType, positionFixedRate, startDateTime).then(fetch);
    }

    async function startIntervention(
        blueprintId: Blueprint['id'],
        runningType: InterventionSchedule['runningType'],
        positionFixedRate: InterventionSchedule['positionFixedRateMs'],
        startDateTime: Intervention['startDateTime']
    ) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        return SimulatorAPI.startIntervention(auth, selectedOrganisation.id, blueprintId, runningType, positionFixedRate, startDateTime).then(fetch);
    }

    async function closeIntervention(interventionId: Intervention['id']) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.closeIntervention(auth, selectedOrganisation.id, interventionId).then(fetch);
    }

    async function closeInterventions() {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.closeInterventions(auth, selectedOrganisation.id).then(fetch);
    }

    async function scheduleIntervention(scheduledInterventionDto: InterventionSchedule) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.scheduleIntervention(auth, selectedOrganisation.id, scheduledInterventionDto).then(fetch);
    }

    async function updateSchedule(scheduledInterventionDto: InterventionSchedule) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.updateScheduledIntervention(auth, selectedOrganisation.id, scheduledInterventionDto.id, scheduledInterventionDto).then(fetch);
    }

    async function deleteSchedule(scheduleId: InterventionSchedule['id']) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.deleteScheduledIntervention(auth, selectedOrganisation.id, scheduleId).then(fetch);
    }

    // --- Boot load --- //
    useEffect(() => {
        fetch();
    }, [selectedOrganisation]);


    return {
        interventions,
        scheduledInterventions,
        fetch,
        instantIntervention,
        startIntervention,
        closeIntervention,
        closeInterventions,
        scheduleIntervention,
        updateSchedule,
        deleteSchedule
    }
}
