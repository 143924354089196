import {TextField} from "@mui/material";
import React, {useState} from 'react';
import {Equipment} from "../../../../types/beemove-masterdata";
import {EquipmentModal} from "./modal/equipment-modal";

interface Props {
    label: string;
    selectedEquipment: Equipment,

    onEquipmentSelected(equipment: Equipment): void
}

export function EquipmentSelect({label, selectedEquipment, onEquipmentSelected}: Props) {
    const [showModal, setShowModal] = useState(false);
    return (<React.Fragment>
        <TextField
            sx={{left: 0}} // Weird hack
            InputLabelProps={{shrink: true}}
            label={label}
            variant={'standard'}
            value={selectedEquipment?.name}
            onClick={() => setShowModal(true)}
        />
        {showModal && (<EquipmentModal
            setShowModal={setShowModal}
            onEquipmentSelected={onEquipmentSelected}
        />)}
    </React.Fragment>);
}
