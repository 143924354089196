import React, {PropsWithChildren} from "react";

import '../_containers.scss';

export const LoginModule = ({children}: PropsWithChildren) => {
    return (<div className='app-container'>
        <div className='login-module'>
            {children}
        </div>
    </div>);
}
