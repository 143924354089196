import {Box} from "@mui/material";
import React from 'react';
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

// @ts-ignore FIXME
import BOSCHUNG_LOGO from '../../../res/boschung_logo.png';

import {MUIStyle} from "../../../styles/mui";

import {modules, Route} from "../../constants/modules";
import OrganisationSelect from "../select/organisation-select";
import './style.scss';

export function Sidemenu() {
    const {t} = useTranslation();

    function renderRoute(routeName: string, routeArgs: Route) {
        return (<NavLink
            key={routeName}
            exact={true}
            className={'sidemenu-link'}
            activeClassName={'sidemenu-link__active'}
            to={routeArgs.path}
        >
            {t(routeName)}
        </NavLink>);
    }

    return (<div className={'sidemenu'}>
        <div className={'sidemenu-header'}>
            <img className={'sidemenu-header__image'} src={BOSCHUNG_LOGO}/>
            <Box sx={MUIStyle} className={'flex-container expand full-width'}>
                <OrganisationSelect/>
            </Box>
        </div>
        <div className={'sidemenu-body'}>
            {Object.entries(modules.app.routes).map(([routeName, routeArgs]) => renderRoute(routeName, routeArgs))}
        </div>
        <div className={'sidemenu-footer'}>
            <p>v {process.env.REACT_APP_VERSION}</p>
        </div>
    </div>);
}
