import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useConfigManager} from "../../../utils/hooks/data/providers/config";
import {hasData} from "../../../utils/toolbox";
import {Select} from "../../tmp/select";

export function CoOperatorSelect() {
    const {t} = useTranslation();
    const {
        availableOperators, selectedCoOperator, setSelectedCoOperator
    } = useConfigManager();
    const [operators, setOperators] = useState([]);

    useEffect(() => {
        setOperators(selectedCoOperator ? [...availableOperators, selectedCoOperator] : availableOperators);
    }, [selectedCoOperator, availableOperators]);

    if (!hasData(availableOperators) && !selectedCoOperator) {
        return null;
    }

    return <Select
        label={t('select_operators_co_operator')}
        setSelectedData={setSelectedCoOperator}
        selectedData={selectedCoOperator}
        data={operators}
    />;
}
