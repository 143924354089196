import React from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {ORGANISATION_ID} from "../../constants/storage/keys";
import {useOrganisationManager} from "../../utils/hooks/providers/organisation";
import {isNullOrUndefined} from "../../utils/toolbox";
import {Loading} from "../loading/loading";
import {Select} from "../tmp/select";

export function OrganisationSelect() {
    const {t} = useTranslation();
    const localOrganisationId = localStorage.getItem(ORGANISATION_ID);
    const {
        organisations, selectedOrganisation, setSelectedOrganisation
    } = useOrganisationManager();
    return (!Number(localOrganisationId) || !isNullOrUndefined(selectedOrganisation)) ? (<Select
        label={t('select_organisations')}
        setSelectedData={setSelectedOrganisation}
        selectedData={selectedOrganisation}
        data={organisations}
    />) : <Loading/>
}

export default withTranslation()(OrganisationSelect);
