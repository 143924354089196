import React, {useEffect, useState} from "react";
import {Blueprint, InterventionPosition} from "../../../../types/intervention-simulator";
import {SimulatorAPI} from '../../../api/intervention/simulator';
import {isIterable, isNullOrUndefined} from "../../toolbox";
import {useAuth} from "../providers/auth";
import {useOrganisationManager} from "../providers/organisation";
import {useConfigManager} from "./providers/config";
import {useTrackManager} from "./providers/tracks";

export interface BlueprintState {
    blueprints: Blueprint[],
    selectedBlueprint: Blueprint,
    setSelectedBlueprint: React.Dispatch<Blueprint>,

    fetch(): Promise<void>,

    createBlueprint(blueprintName: Blueprint['name'], source: Blueprint['source']): Promise<void>,

    deleteBlueprint(blueprintId: Blueprint["id"]): Promise<void>,

    updateBlueprint(blueprint: Blueprint, positions: InterventionPosition[]): Promise<void>
}

// Provider hook that creates auth object and handles state
export function useProvideBlueprintManager(): BlueprintState {
    const auth = useAuth();
    const {selectedOrganisation} = useOrganisationManager();
    const {selectedConfig} = useConfigManager();
    const {selectedTrack} = useTrackManager();

    // Data
    const [blueprints, _setBlueprints] = useState<Blueprint[]>([]);
    const [selectedBlueprint, _setSelectedBlueprint] = useState<Blueprint | null>(null);

    function setSelectedBlueprint(blueprint: Blueprint) {
        if (!isNullOrUndefined(blueprint) && !isIterable(blueprint.positions) && !isNullOrUndefined(selectedOrganisation?.id)) {
            SimulatorAPI.getBlueprint(auth, selectedOrganisation.id, blueprint.id).then(_setSelectedBlueprint);
        } else {
            _setSelectedBlueprint(blueprint);
        }
    }

    // --- Local state --- //
    useEffect(() => {
        _setSelectedBlueprint(null);
    }, [selectedTrack]);

    // --- Boot load --- //
    useEffect(() => {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        fetch();
    }, [selectedOrganisation]);

    // --- Functions --- //
    async function fetch() {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        SimulatorAPI.getBlueprints(auth, selectedOrganisation.id).then(_setBlueprints);
    }

    async function createBlueprint(blueprintName: Blueprint['name'], source: Blueprint['source']) {
        if (isNullOrUndefined(selectedOrganisation?.id) || isNullOrUndefined(selectedConfig) || isNullOrUndefined(selectedTrack)) {
            return;
        }

        const blueprintDto: Blueprint = {
            "name": blueprintName,
            "source": source,
            "interventionConfigId": selectedConfig.id,
            "trackId": selectedTrack.id,
            "positions": selectedTrack.positions.map(position => Object.assign({}, {...position}, {
                activities: [], measures: [],
            })),
            "medias": [], // TODO !!!
            "activityReports": [] // TODO !!!
        }

        return await SimulatorAPI.createBlueprint(auth, selectedOrganisation.id, blueprintDto).then(fetch);
    }

    async function deleteBlueprint(blueprintId: Blueprint["id"]) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        return await SimulatorAPI.deleteBlueprint(auth, selectedOrganisation.id, blueprintId)
            .then(fetch)
            .then(() => setSelectedBlueprint(null));
    }

    async function updateBlueprint(blueprint: Blueprint, positions: InterventionPosition[]) {
        if (isNullOrUndefined(selectedOrganisation?.id)) return;
        return await SimulatorAPI.updateBlueprint(auth, selectedOrganisation.id, Object.assign({}, blueprint, {positions}));
    }

    // Export methods
    return {
        blueprints, selectedBlueprint, setSelectedBlueprint,

        fetch, createBlueprint, deleteBlueprint, updateBlueprint
    }
}
