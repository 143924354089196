// Local/Session
export const KEY_LOCAL_STORAGE = 'KLS';
export const KEY_SESSION_STORAGE = 'KSS';

// Hooks/auth
export const KSS_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';
export const KSS_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';

export const ORGANISATION_ID = 'organisation_id';

// Hooks/config
export const INTERVENTION_CONFIG_ID = 'interventionconfig_id'

// Hooks/masterdata
export const MASTERDATA_ACTIVITIES = 'masterdata_activities';
export const MASTERDATA_CENTERS = 'masterdata_centers';
export const MASTERDATA_CONSUMABLES = 'masterdata_consumables';
export const MASTERDATA_STATUSES = 'masterdata_statuses';
export const MASTERDATA_EQUIPMENT = 'masterdata_equipment';

// Hooks/track
export const TRACK_SELECTED = 'track_selected'
