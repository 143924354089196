import React from 'react';
import {useTranslation} from "react-i18next";
import {useConfigManager} from "../../utils/hooks/data/providers/config";
import {hasData} from "../../utils/toolbox";
import {Loading} from "../loading/loading";
import {MultiSelect} from "../tmp/multi-select";

export function EquipmentMultiSelect() {
    const {t} = useTranslation();
    const {selectedConfig, selectedCenter, selectedEquipment, setSelectedEquipment} = useConfigManager();

    return (!hasData(selectedConfig?.equipments) || hasData(selectedEquipment)) ? (<MultiSelect
        label={t('select_equipment')}
        setSelectedData={setSelectedEquipment}
        value={selectedEquipment}
        options={selectedCenter.equipment}
    />) : <Loading/>;
}
