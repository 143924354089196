import React, {createContext, useContext} from "react";
import {TrackState, useProvideTrackManager} from "../track";

const DataContext = createContext<TrackState>(undefined);

export function ProvideTrackManager({children}) {
    const dataManager = useProvideTrackManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useTrackManager = () => {
    return useContext(DataContext);
};
