import React from 'react';
import {useTranslation} from "react-i18next";
import {INTERVENTION_CONFIG_ID} from "../../constants/storage/keys";
import {useConfigManager} from "../../utils/hooks/data/providers/config";
import {isNullOrUndefined} from "../../utils/toolbox";
import {Loading} from "../loading/loading";
import {Select} from "../tmp/select";

export function ConfigSelect() {
    const {t} = useTranslation();
    const localConfigId = localStorage.getItem(INTERVENTION_CONFIG_ID);
    const {configs, selectedConfig, setSelectedConfig} = useConfigManager();
    return (!Number(localConfigId) || !isNullOrUndefined(selectedConfig)) ? (<Select
        label={t('select_configs')}
        setSelectedData={setSelectedConfig}
        selectedData={selectedConfig}
        data={configs}
    />) : <Loading/>
}
