import React, {createContext, PropsWithChildren, useContext} from "react";
import {OrganisationState, useProvideOrganisationManager} from "../organisation";

const DataContext = createContext(undefined);

/* --- KEYS --- */

export function ProvideOrganisationManager({children}: PropsWithChildren) {
    const dataManager = useProvideOrganisationManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useOrganisationManager = (): OrganisationState => {
    return useContext(DataContext);
};
