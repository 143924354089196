import {TextField} from "@mui/material";
import React, {useState} from 'react';
import {Activity, ActivityOption} from "../../../../types/beemove-masterdata";
import ActivitiesModal from "./modal/activities-modal";

interface Props {
    label: string;
    selectedActivity: Activity;

    onActivitySelected(data: { activityId: Activity['id'], option: ActivityOption['position'] }): void;
}

export function ActivitySelect({label, selectedActivity, onActivitySelected}: Props) {
    const [showModal, setShowModal] = useState(false);
    return (<React.Fragment>
        <TextField
            sx={{left: 0}} // Weird hack
            InputLabelProps={{shrink: true}}
            label={label}
            variant={'standard'}
            value={selectedActivity?.name}
            onClick={() => setShowModal(true)}
        />
        {showModal && (<ActivitiesModal
            setShowModal={setShowModal}
            onActivitySelected={onActivitySelected}
        />)}
    </React.Fragment>);
}
