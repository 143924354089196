import {Box} from "@mui/material";
import moment from "moment";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MUITableStyle} from "../../../../../styles/mui";
import {interventionDtoExample} from "../../../../constants/examples/interventionDtoExample";
import {useSimulatorManager} from "../../../../utils/hooks/data/providers/simulator";
import {removeFromArray} from "../../../../utils/toolbox";
import {Loading} from "../../../loading/loading";

import {OkCancelModal} from "../../../modal/ok-cancel-modal";
import {DenseTable} from "../../../tables/dense-table";
import {InterventionModal} from "./modals/intervention-modal";

// Style
import './style.scss';

export function InterventionsTable() {
    const {t} = useTranslation();
    const {interventions, closeIntervention} = useSimulatorManager();

    const [rows, setRows] = useState(null);
    const [clickedId, setClickedId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const headers = Object.keys(interventionDtoExample);
    const ignored = removeFromArray(Object.keys(interventionDtoExample), 'id');

    function sortInterventions(i) {
        // Sort by chassisNo
        return i.sort((a, b) => {
            if (moment(a.startDateTime).isSame(b.startDateTime)) {
                if (moment(a.endDateTime).isSame(b.endDateTime)) return a.id - b.id;
                return moment(a.endDateTime).isAfter(b.endDateTime) ? -1 : 1;
            } else {
                return moment(a.startDateTime).isAfter(b.startDateTime) ? -1 : 1;
            }
        });
    }

    useEffect(() => {
        setRows(sortInterventions(interventions));
    }, [interventions]);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedInterventionId, setSelectedInterventionId] = useState(null);

    function _openConfirmModal(id) {
        setSelectedInterventionId(id);
        setShowConfirmModal(true);
    }

    function _onOk() {
        closeIntervention(selectedInterventionId);
        setShowConfirmModal(false);
    }

    function _onCancel() {
        setShowConfirmModal(false);
    }

    return Array.isArray(rows) ? (<Box sx={MUITableStyle} className={'half-height'}>
        <DenseTable
            headers={headers}
            ignored={ignored}
            translations={{'id': t('intervention_table_header_id')}}
            items={rows}
            onRowClick={intervention => {
                setClickedId(intervention.id);
                setShowModal(true);
            }}
            actions={(intervention) => [{
                'iconName': 'delete', 'callback': () => _openConfirmModal(intervention.id)
            }]}
            itemCountPerPage={5}
        />
        {showModal && <InterventionModal setShowModal={setShowModal} clickedId={clickedId}/>}
        {showConfirmModal && (<OkCancelModal
            title={t('modal_confirm_title')}
            content={t('modal_confirm_body')}
            onOk={_onOk}
            onCancel={_onCancel}
        />)}
    </Box>) : <Loading/>;
}
