import {Button} from "@boschung/common-react-components";
import {ReCron, Tab} from "@sbzen/re-cron";
import React from 'react';
import {useTranslation} from "react-i18next";
import {InterventionSchedule} from "../../../../../../types/intervention-simulator";
import {
    BasicModal, BasicModalContent, BasicModalFooter, BasicModalHeader, Props as ModalProps
} from "../../../../modal/basic-modal";

interface Props extends ModalProps {
    cronValue: InterventionSchedule['cron'];
    setCronValue: React.Dispatch<InterventionSchedule['cron']>
}

export function CronModal({setShowModal, cronValue, setCronValue}: Props) {
    const {t} = useTranslation();
    return (<BasicModal setShowModal={setShowModal}>
        <BasicModalHeader>
            <h1>{t('modal_cron_title')}</h1>
        </BasicModalHeader>
        <BasicModalContent>
            <ReCron
                tabs={[Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH]}
                value={cronValue}
                onChange={setCronValue}
            />
        </BasicModalContent>
        <BasicModalFooter>
            <Button
                type={'button'}
                text={t('ok')}
                onClick={() => setShowModal(false)}
            />
        </BasicModalFooter>
    </BasicModal>);
}
