import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as MUIRadioGroup} from "@mui/material";
import React from 'react';
import {useTranslation} from "react-i18next";

export function RadioGroup({className, name, label, options, orientation, onChange, value = options[0]}) {
    const {t} = useTranslation();
    return (<FormControl className={className}>
        <FormLabel id={`${name}-label`}>
            {label}
        </FormLabel>
        <MUIRadioGroup
            row={orientation === 'horizontal'}
            aria-orientation={orientation}
            aria-labelledby={`${name}-label`}
            name={name}
            onChange={onChange}
            value={value}
        >
            {options.map((option: string) => (<FormControlLabel
                key={option}
                value={option}
                control={<Radio/>}
                label={t(option)}
            />))}
        </MUIRadioGroup>
    </FormControl>);
}
