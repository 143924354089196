import axios from 'axios';
import {stringify} from 'qs';
import {HTTP} from "../constants/enums/http";
import {isNullOrUndefined} from "../utils/toolbox";

export default {
    clientId: 'boschung-services',
};

function handleError(e, url, method) {
    const error = e.response;
    if (error) {
        console.error(`Request failed with status ${error.status} {
			url : ${url}
			vrb : ${method}
			err : ${error.data.error}
			msg : ${error.data.message}
			tms : ${error.data.timestamp}
		}`);
    } else {
        console.error(e);
    }
}

/**
 * @throws errors if request failed.
 * @param {*} method
 * @param {*} auth
 * @param {*} url
 * @param {*} data
 */
async function request(method, auth, url, data) {
    console.debug("API request:", method, auth, url, data);
    try {
        if (!auth.isAuthenticated) throw new Error("Authenticated Entity required to call this resource");
        const token = await auth.getAccessToken();
        if (isNullOrUndefined(token)) return null;
        const response = await axios({
            url, data, method, headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': (data instanceof FormData ? 'multipart/form-data' : 'application/json')
            }
        });
        return response.data || true;
    } catch (e) {
        handleError(e, url, method);
        throw e;
    }
}

export async function get(auth, url) {
    return request(HTTP.GET, auth, url, null);
}

export async function put(auth, url, payload) {
    return request(HTTP.PUT, auth, url, payload);
}

export async function post(auth, url, payload) {
    return request(HTTP.POST, auth, url, payload);
}

export async function del(auth, url) {
    return request(HTTP.DELETE, auth, url, null);
}

export async function encodedGet(auth, url) {
    return requestUrlEncoded(HTTP.GET, auth, url, null);
}

export async function encodedPut(auth, url, payload) {
    return requestUrlEncoded(HTTP.PUT, auth, url, payload);
}

export async function encodedPost(auth, url, payload) {
    return requestUrlEncoded(HTTP.POST, auth, url, payload);
}

export async function encodedDel(auth, url) {
    return requestUrlEncoded(HTTP.DELETE, auth, url, null);
}

/**
 * @throws errors if request failed.
 * @param {*} method
 * @param {*} auth
 * @param {*} url
 * @param {*} data
 */
export async function requestUrlEncoded(method, auth, url, data) {
    try {
        const token = await auth.getAccessToken();
        if (isNullOrUndefined(token)) return null;
        const options = {
            method, data: stringify(data), headers: {
                authorization: 'Bearer ' + token, 'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const response = await axios(url, options);
        return response.data || true;
    } catch (e) {
        handleError(e, url, method);
        throw e;
    }
}
