import React, {PropsWithChildren} from "react";
import {Sidemenu} from "../../sidemenu/sidemenu";

// CSS
import '../_containers.scss';

export const AppModule = ({children}: PropsWithChildren) => {
    // TODO: Inject Manager only when necessary
    return (<div className='app-container'>
        <Sidemenu/>
        <div className='app-module'>
            {children}
        </div>
    </div>);
}
