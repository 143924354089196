import {Button} from "@boschung/common-react-components";
import {Box} from "@mui/material";
import React, {useEffect, useState} from 'react';
import '../../../../select/activities/modal/style.scss';
import {useTranslation} from "react-i18next";
import {MUIStyle, MUITableStyle} from "../../../../../../styles/mui";
import {useMasterdataManager} from "../../../../../utils/hooks/data/providers/masterdata";
import {useTrailManager} from "../../../../../utils/hooks/data/providers/trail";
import {hasData, isNullOrUndefined, removeFromArray} from "../../../../../utils/toolbox";
import {OkCancelModal} from "../../../../modal/ok-cancel-modal";
import {ActivitySelect} from "../../../../select/activities/activity-select";
import {DenseTable} from "../../../../tables/dense-table";
import {Select} from "../../../../tmp/select";

export function ActivitiesForm() {
    const {t} = useTranslation();
    const {activities} = useMasterdataManager();
    const {selectedPosition, positions, updateTrail} = useTrailManager();
    const [selectedEndPosition, setSelectedEndPosition] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    function addActivity(startPositionSequenceNumber, stopPositionSequenceNumber, activityId, option) {
        const spsn = Math.min(startPositionSequenceNumber - 1, stopPositionSequenceNumber);
        const epsn = Math.max(startPositionSequenceNumber - 1, stopPositionSequenceNumber);
        positions.slice(spsn, epsn)
            .forEach(position => {
                // Activity is ignored if already present
                if (isNullOrUndefined(position.activities.find(a => a.activityId === selectedActivityId))) {
                    position.activities.push({
                        activityId, option
                    });
                }
            });
        // Update trail
        updateTrail();
    }

    function _iterateAndRemoveActivity(activityId, start, condition, reverse) {
        for (let i = start; condition(i); i += (reverse ? -1 : 1)) {
            const position = positions[i];
            if (hasData(position.activities)) {
                const metaActivity = position.activities.find(a => a.activityId === activityId);
                if (isNullOrUndefined(metaActivity)) break;
                removeFromArray(position.activities, metaActivity);
            }
        }
    }

    /**
     * Removes activity from the selected position and <-- -->
     * @param activityId
     */
    function removeActivity(activityId) {
        _iterateAndRemoveActivity(activityId, selectedPosition.sequenceNumber - 1, i => i < positions.length, false);
        _iterateAndRemoveActivity(activityId, selectedPosition.sequenceNumber - 1, i => i >= 0, true);

        // Update trail
        updateTrail();
    }

    const [sp, setSp] = useState(null);
    useEffect(() => {
        if (!isNullOrUndefined(selectedEndPosition)) {
            setSp(positionToOption(selectedEndPosition));
        } else {
            setSp(null);
        }
    }, [selectedEndPosition]);

    function positionToOption(p) {
        return {id: p.sequenceNumber, name: 'position_' + p.sequenceNumber};
    }

    function onActivitySelected(data) {
        setSelectedActivity(activities.find(activity => activity.id === data.activityId));
        setSelectedActivityId(data.activityId);
        setSelectedOption(data.option);
    }

    // Confirmation modal
    const [showModal, setShowModal] = useState(false);
    const [modalValueId, setModalValueId] = useState(null);

    function _openModal(id) {
        setModalValueId(id);
        setShowModal(true);
    }

    function _onOk() {
        removeActivity(modalValueId);
        setShowModal(false);
    }

    function _onCancel() {
        setShowModal(false);
    }

    // Render form
    const items = hasData(selectedPosition?.activities) ? selectedPosition?.activities.map(({activityId, option}) => {
        return Object.assign({}, activities.find(a => a.id === activityId), {option});
    }) : [];
    return (<Box sx={MUIStyle} className={'flex-container__column expand'}>
        <Box className={'expand'} sx={MUITableStyle}>
            <DenseTable
                itemCountPerPage={5}
                headers={['name', 'option']}
                items={items}
                actions={(activityWithOption) => [{
                    iconName: 'delete', callback: () => _openModal(activityWithOption.id)
                }]}
            />
            {showModal && (<OkCancelModal
                title={t('modal_confirm_title')}
                content={t('modal_confirm_body')}
                onOk={_onOk}
                onCancel={_onCancel}
            />)}
        </Box>
        {selectedPosition && (<div className={'flex-container__column shrink'}>
            <div className={'flex-container__row center'}>
                <ActivitySelect
                    label={t('form_activities_new_activity')}
                    selectedActivity={selectedActivity}
                    onActivitySelected={onActivitySelected}
                />
            </div>
            <div className={'flex-container__row center'}>
                <Select
                    label={t('form_activities_to')}
                    data={positions.map(positionToOption)}
                    setSelectedData={p => setSelectedEndPosition(positions.find(_p => _p.sequenceNumber === p.id))}
                    selectedData={sp}
                />
            </div>
            <Button
                text={t('form_activities_add_activity')}
                onClick={() => addActivity(selectedPosition.sequenceNumber, selectedEndPosition.sequenceNumber, selectedActivityId, selectedOption,)}
            />
        </div>)}
    </Box>);
}
