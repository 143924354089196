import React from 'react';
import {useTranslation} from "react-i18next";
import {useTrackManager} from "../../utils/hooks/data/providers/tracks";
import {Select} from "../tmp/select";

export function TrackSelect() {
    const {t} = useTranslation();
    const {
        tracks, selectedTrack, setSelectedTrack
    } = useTrackManager();

    return (<Select
        label={t('select_tracks')}
        setSelectedData={setSelectedTrack}
        selectedData={selectedTrack}
        data={tracks}
    />);
}
