import {Intervention} from "../../../types/beemove-intervention";
import {Organisation} from "../../../types/beemove-masterdata";
import {Blueprint, InterventionConfig, InterventionSchedule, Track} from "../../../types/intervention-simulator";
import {URL_SERVICES_SIMULATOR} from "../../constants/urls";
import {AuthState} from "../../utils/hooks/auth";
import {isNullOrUndefined} from "../../utils/toolbox";
import {del, encodedPost, get, post, put} from "../api";

function call<T>(fnc: (auth: AuthState, url: string, data?: unknown) => Promise<T | null>,
    auth: AuthState,
    endpoint: string,
    organisationId?: Organisation['id'],
    data?: unknown
) {
    let url = URL_SERVICES_SIMULATOR;
    if (!isNullOrUndefined(organisationId)) {
        url += `organisations/${organisationId}/`;
    }
    url += endpoint;
    return fnc(auth, url, data);
}

export const SimulatorAPI = {
    // --- Blueprints --- //
    async getBlueprints(auth: AuthState, organisationId: Organisation['id']) {
        return await call<Blueprint[]>(get, auth, 'interventions', organisationId);
    },

    async getBlueprint(auth: AuthState,
        organisationId: Organisation['id'],
        blueprintId: Blueprint['id']
    ): Promise<Blueprint | null> {
        return await call<Blueprint>(get, auth, `interventions/${blueprintId}`, organisationId);
    },

    async createBlueprint(auth: AuthState, organisationId: Organisation['id'], blueprint: Blueprint) {
        return await call<number>(post, auth, 'interventions', organisationId, blueprint);
    },

    async updateBlueprint(auth: AuthState, organisationId: Organisation['id'], blueprint: Blueprint) {
        return await call<void>(put, auth, `interventions/${blueprint.id}`, organisationId, blueprint);
    },

    async deleteBlueprint(auth: AuthState, organisationId: Organisation['id'], blueprintId: Blueprint['id']) {
        return await call<void>(del, auth, `interventions/${blueprintId}`, organisationId);
    },

    // --- Intervention Config --- //
    async getConfigs(auth: AuthState, organisationId: Organisation['id']) {
        return await call<InterventionConfig[]>(get, auth, 'interventionconfig', organisationId);
    },

    async createConfig(auth: AuthState, organisationId: Organisation['id'], config: InterventionConfig) {
        return await call<InterventionConfig['id']>(post, auth, 'interventionconfig', organisationId, config);
    },

    async updateConfig(auth: AuthState, organisationId: Organisation['id'], config: InterventionConfig) {
        return await call<void>(put, auth, `interventionconfig/${config.id}`, organisationId, config);
    },

    async deleteConfig(auth: AuthState, organisationId: Organisation['id'], configId: InterventionConfig['id']) {
        return await call<void>(del, auth, `interventionconfig/${configId}`, organisationId);
    },

    // --- Live interventions --- //
    async getInterventions(auth: AuthState, organisationId: Organisation['id']) {
        return await call<Intervention[]>(get, auth, 'interventions/live', organisationId);
    },

    async closeIntervention(auth: AuthState, organisationId: Organisation['id'], interventionId: Intervention['id']) {
        return await call<void>(put, auth, `interventions/live/${interventionId}/close`, organisationId);
    },

    async closeInterventions(auth: AuthState, organisationId: Organisation['id']) {
        return await call<void>(put, auth, `interventions/live/close`, organisationId);
    },

    // --- Media --- //
    // TODO

    // --- Organisation --- //
    async getOrganisations(auth: AuthState) {
        return await call<Organisation[]>(get, auth, 'organisation');
    },

    // --- Scheduled Interventions --- //
    async getScheduledInterventions(auth: AuthState, organisationId: Organisation['id']) {
        return await call<InterventionSchedule[]>(get, auth, `scheduledintervention`, organisationId);
    },

    async scheduleIntervention(auth: AuthState, organisationId: Organisation['id'], data) {
        return await call(post, auth, 'scheduledintervention', organisationId, data);
    },

    async updateScheduledIntervention(auth: AuthState,
        organisationId: Organisation['id'],
        scheduledInterventionId,
        data
    ) {
        return await call(put, auth, `scheduledintervention/${scheduledInterventionId}`, organisationId, data);
    },

    async deleteScheduledIntervention(auth: AuthState, organisationId: Organisation['id'], scheduledInterventionId) {
        return await call(del, auth, `scheduledintervention/${scheduledInterventionId}`, organisationId);
    },

    // --- Simulate Interventions --- //
    async instantIntervention(auth: AuthState,
        organisationId: Organisation['id'],
        blueprintId,
        runningType,
        positionFixedRate,
        startDateTime
    ) {
        return await call(encodedPost, auth, `interventions/${blueprintId}/simulate/instant`, organisationId, {
            runningType, positionFixedRate, startDateTime
        });
    },

    async startIntervention(auth: AuthState,
        organisationId: Organisation['id'],
        blueprintId,
        runningType,
        positionFixedRate,
        startDateTime
    ) {
        return await call(encodedPost, auth, `interventions/${blueprintId}/simulate/start`, organisationId, {
            runningType, positionFixedRate, startDateTime
        });
    },

    // --- Tracks --- //
    async getTracks(auth: AuthState) {
        return await call<Track[]>(get, auth, 'tracks');
    },

    async createTrack(auth: AuthState, data) {
        return await call<Track['id']>(post, auth, 'tracks', null, data);
    },

    async deleteTrack(auth: AuthState, trackId: Track['id']) {
        return await call<void>(del, auth, `tracks/${trackId}`);
    },
}

