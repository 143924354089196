import React, {useEffect, useState} from 'react';
import {KEY_LOCAL_STORAGE, KEY_SESSION_STORAGE} from "../../constants/storage/keys";
import {isNullOrUndefined} from "../toolbox";

type StorageType = 'local' | 'session';

interface Storage {
    prefix: string,
    container: typeof localStorage | typeof sessionStorage
}

const STORAGES: Record<StorageType, Storage> = {
    local: {
        prefix: KEY_LOCAL_STORAGE, container: localStorage
    }, session: {
        prefix: KEY_SESSION_STORAGE, container: sessionStorage
    }
};

function getStorage(name: StorageType) {
    return STORAGES[name];
}

const useStateWithStorage = <T>(type: StorageType, key: string, defaultValue: T): [T, React.Dispatch<T>] => {
    const {prefix, container} = getStorage(type);
    const storageKey = `${prefix}_${key}`;

    function getItem(): T {
        return JSON.parse(container.getItem(storageKey)) || defaultValue;
    }

    function removeItem() {
        console.debug(`Removing item with key ${storageKey} from storage…`);
        container.removeItem(storageKey);
    }

    function saveItem(item: T) {
        console.debug(`Updating item with key ${storageKey} from storage…`);
        container.setItem(storageKey, JSON.stringify(item));
    }

    // State
    const [value, setValue] = useState(getItem());

    // Keep up-to-date
    useEffect(() => {
        if (isNullOrUndefined(value)) {
            removeItem();
        } else {
            saveItem(value);
        }
    }, [value]);
    return [value, setValue];
}

export const useStateWithLocalStorage = <T>(key: string, defaultValue: T) => {
    return useStateWithStorage('local', key, defaultValue);
}

export const useStateWithSessionStorage = <T>(key: string, defaultValue: T) => {
    return useStateWithStorage('session', key, defaultValue);
}
