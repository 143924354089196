import React, {ReactNode} from "react";
import {ConfigForm} from "../components/modules/app/config/config-form";
import EditorForm from "../components/modules/app/editor/editor-form";
import Logout from "../components/modules/app/logout/logout";
import Scheduler from "../components/modules/app/scheduler/scheduler-form";
import {LoginForm} from "../components/modules/login/login/login-form";

export type Modules = 'login' | 'app';

export interface Module {
    redirect: string;
    routes: Record<string, Route>
}

export interface Route {
    path: string;

    component(props: any): ReactNode
}

export const modules: Record<Modules, Module> = {
    login: {
        redirect: '/login', routes: {
            login: {
                path: '/login', component: (props) => <LoginForm {...props} />
            }
        }
    }, app: {
        redirect: '/config', routes: {
            config: {
                path: '/config', component: props => <ConfigForm {...props} />
            }, editor: {
                path: '/editor', component: props => <EditorForm {...props} />
            }, scheduler: {
                path: '/scheduler', component: props => <Scheduler {...props} />
            }, logout: {
                path: '/logout', component: props => <Logout {...props} />
            }
        }
    }
};
