import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Operator} from "../../../../types/beemove-masterdata";
import {useConfigManager} from "../../../utils/hooks/data/providers/config";
import {hasData} from "../../../utils/toolbox";
import {MultiSelect} from "../../tmp/multi-select";

export function OperatorMultiSelect() {
    const {t} = useTranslation();
    const {availableOperators, selectedOperators, setSelectedOperators} = useConfigManager();

    const [operators, setOperators] = useState<Operator[]>([]);

    useEffect(() => {
        setOperators(hasData(selectedOperators) ? [...availableOperators, ...selectedOperators] : availableOperators);
    }, [selectedOperators, availableOperators]);

    if (!hasData(availableOperators) && !selectedOperators) {
        return null;
    }

    // @ts-ignore
    return <MultiSelect
        label={t('select_operators_operators')}
        setSelectedData={setSelectedOperators}
        value={selectedOperators}
        options={operators}
    />;
}
