import {Autocomplete, AutocompleteProps, FormControl, Input, InputLabel, MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from "react-i18next";

const menuItemStyle = {
    backgroundColor: '$color-boschung-main', ':hover': {
        background: '$color-boschung-main-light'
    }
};

interface Props<T> extends Omit<AutocompleteProps<T, any, any, any>, 'renderInput' | 'renderOption' | 'multiple' | 'filterSelectedOptions' | 'getOptionLabel' | 'onChange'> {
    label: string;
    setSelectedData: React.Dispatch<T[]>;
}

export function MultiSelect<T>({label, options, value, setSelectedData, ...props}: Props<T>) {
    const {t} = useTranslation();

    function handleChange(_, newValues: T[]) {
        setSelectedData(newValues);
    }

    function renderOption(p, option) {
        return (<MenuItem
            value={option.id}
            style={menuItemStyle}
            {...p}
        >
            {option.name}
        </MenuItem>);
    }

    function renderInput(params) {
        return (<Input
            {...params}
            {...params.InputProps}
        />);
    }

    return (<FormControl className={'flex-container'}>
        {label && (<InputLabel htmlFor={label} shrink={true}>
            {label}
        </InputLabel>)}
        <Autocomplete
            {...props}
            id={props.id || label}
            multiple
            filterSelectedOptions
            renderOption={renderOption}
            renderInput={renderInput}
            getOptionLabel={option => {
                return options && options.length ? ((option?.key || option?.name || option) ?? t('none')) : t('nothing_found');
            }}
            options={options}
            onChange={handleChange}
            value={value}
        />
    </FormControl>);
}

MultiSelect.defaultProps = {
    variant: 'standard', limitTags: 2
};

MultiSelect.propTypes = {
    label: PropTypes.string, data: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.object, value: PropTypes.object
    })).isRequired, selectedData: PropTypes.object || PropTypes.number, setSelectedData: PropTypes.func.isRequired,
};
