import React from 'react';
import {useTranslation} from "react-i18next";
import {useConfigManager} from "../../utils/hooks/data/providers/config";
import {useMasterdataManager} from "../../utils/hooks/data/providers/masterdata";
import {isNullOrUndefined} from "../../utils/toolbox";
import {Loading} from "../loading/loading";
import {Select} from "../tmp/select";

export function CenterSelect() {
    const {t} = useTranslation();
    const {centers} = useMasterdataManager();
    const {selectedConfig, selectedCenter, setSelectedCenter} = useConfigManager();
    return (!Number(selectedConfig?.centerId) || !isNullOrUndefined(selectedCenter)) ? (<Select
        label={t('select_centers')}
        setSelectedData={setSelectedCenter}
        selectedData={selectedCenter}
        data={centers}
    />) : <Loading/>;
}
