import {ClickAwayListener} from "@mui/material";
import './_modal.scss';
import React, {PropsWithChildren} from 'react';
import ReactDOM from 'react-dom';

export interface Props {
    setShowModal: React.Dispatch<boolean>
}

export const BasicModal = ({children, setShowModal}: PropsWithChildren<Props>) => {
    return ReactDOM.createPortal(<div className="modal-wrapper">
        <ClickAwayListener onClickAway={() => setShowModal(false)}>
            <div className="modal">
                {children}
            </div>
        </ClickAwayListener>
    </div>, document.getElementById('modal-container'));
};

export const BasicModalHeader = ({children}: PropsWithChildren) => (<div className="modal__header">{children}</div>);


export const BasicModalContent = ({children}: PropsWithChildren) => (<div className="modal__content">{children}</div>);

export const BasicModalFooter = ({children}: PropsWithChildren) => (
    <footer className="modal__footer">{children}</footer>);
