import React, {useEffect, useState} from "react";
import {Track} from "../../../../types/intervention-simulator";
import {SimulatorAPI} from '../../../api/intervention/simulator';
import {TRACK_SELECTED} from "../../../constants/storage/keys";
import {isNullOrUndefined} from "../../toolbox";
import {useAuth} from "../providers/auth";
import {useStateWithLocalStorage} from "../storage";

export interface TrackState {
    tracks: Track[],
    selectedTrack: Track | null,
    setSelectedTrack: React.Dispatch<Track>,

    fetch(): Promise<void>,

    upload(name: string, gpxFile: unknown): Promise<void>,

    deleteTrack(trackId: Track['id']): Promise<void>
}

// Provider hook that creates auth object and handles state
export function useProvideTrackManager(): TrackState {
    const auth = useAuth();

    // --- Local --- //
    const [selectedTrack, setSelectedTrack] = useStateWithLocalStorage<Track>(TRACK_SELECTED, null);
    const [tracks, setTracks] = useState<Track[]>([]);

    async function fetch() {
        const fetchedTracks = await SimulatorAPI.getTracks(auth);
        if (Array.isArray(fetchedTracks)) {
            // TODO: Deep compare to avoid unnecessary reload of data?
            setTracks(fetchedTracks);
        }
    }

    // ---
    useEffect(() => {
        fetch()
    }, []);

    // --- Check if we must unload the track
    useEffect(() => {
        if (isNullOrUndefined(selectedTrack)) return;
        if (isNullOrUndefined(tracks) || isNullOrUndefined(tracks.find(track => track.id === selectedTrack.id))) {
            setSelectedTrack(null);
        }
    }, [tracks]);

    useEffect(() => {
        console.debug("SelectedTrack:", selectedTrack);
    }, [selectedTrack]);

    async function upload(name, gpxFile) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('gpxFile', gpxFile);
        return await SimulatorAPI.createTrack(auth, formData).then(trackId => {
            fetch()
                .then(() => setSelectedTrack(tracks.find(track => track.id === trackId)));
        });
    }

    async function deleteTrack(trackId: Track['id']) {
        return await SimulatorAPI.deleteTrack(auth, trackId)
            .then(fetch)
            .then(() => {
                if (selectedTrack.id === trackId) {
                    setSelectedTrack(null);
                }
            });
    }

    // Export methods
    return {
        tracks, selectedTrack, setSelectedTrack, // TODO: Define if expose this or a new function to select by id for the <input type select>

        fetch, upload, deleteTrack
    }
}
