import {Button} from "@boschung/common-react-components";
import {ClickAwayListener, FormControl, InputLabel, TextField} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useTrackManager} from "../../../../../utils/hooks/data/providers/tracks";
import {isNullOrUndefined} from "../../../../../utils/toolbox";
import {SelectForm} from "../../../../form/select-form";
import {Loading} from "../../../../loading/loading";
import {TrackSelect} from "../../../../select/track-select";
import {UploadButton} from "../../../../tmp/upload-button";

export function TrackForm() {
    const {t} = useTranslation();
    const {selectedTrack, upload, deleteTrack} = useTrackManager();

    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState(null);
    const [isFilenameValid, setIsFilenameValid] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [showFileUploadForm, setShowFileUploadForm] = useState(false);

    useEffect(() => {
        setIsFilenameValid(!isNullOrUndefined(filename) && filename.length > 0);
    }, [filename]);

    function handleFilenameChange(e) {
        setFilename(e.target.value.trim());
    }

    function deleteCurrentTrack() {
        if (!isNullOrUndefined(selectedTrack)) {
            deleteTrack(selectedTrack.id);
        }
    }

    function uploadGPXFile() {
        setIsUploading(true);
        return upload(filename, file)
            .finally(() => {
                setIsUploading(false);
                setShowFileUploadForm(false);
            });
    }

    function renderTrackUploadForm() {
        return (<ClickAwayListener onClickAway={() => setShowFileUploadForm(false)}>
            <div className={'flex-container__column card'}>
                <FormControl>
                    <InputLabel htmlFor={'filename'}/>
                    <TextField
                        id={'filename'}
                        name={'filename'}
                        label={t('form_tracks_field_track_name')}
                        value={filename}
                        onChange={handleFilenameChange}
                        variant={'standard'}
                    />
                </FormControl>
                {isUploading ? <Loading/> : (<div className={'center'}>
                    <UploadButton
                        id={'gpx-uploader'}
                        setFile={setFile}
                    />
                </div>)}
                <div style={{margin: 8}}/>
                <Button
                    disabled={isUploading || isNullOrUndefined(file) || !isFilenameValid}
                    onClick={uploadGPXFile}
                    iconName={'send'}
                />
            </div>
        </ClickAwayListener>);
    }

    return (<div className={'flex-container__column'}>
        <SelectForm
            onAddClick={() => setShowFileUploadForm(true)}
            onDeleteClick={deleteCurrentTrack}
            isAddDisabled={false}
            isDeleteDisabled={isNullOrUndefined(selectedTrack)}
        >
            <TrackSelect/>
        </SelectForm>
        {showFileUploadForm && renderTrackUploadForm()}
    </div>);
}
