import {Button, CMFPasswordInput, CMFTextInput, CustomForm} from '@boschung/common-react-components';
import {Alert} from "@mui/material";
import React, {useState} from 'react';

import {useAuth} from "../../../../utils/hooks/providers/auth";

import './style.scss';

const ERROR_TIME_ON_SCREEN_MS = 10 * 1000;

export function LoginForm() {
    const auth = useAuth();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function displayError(e) {
        setErrorMessage(e)
        setShowError(true);
        setTimeout(() => setShowError(false), ERROR_TIME_ON_SCREEN_MS);
    }

    function performLogin({username, password}) {
        auth.authenticate(username, password)
            .then(r => {
                if (r !== true) {
                    displayError('Operation did not succeed!');
                }
            })
            .catch(displayError);
    }

    return (<CustomForm
        formikProps={{
            initialValues: {}, onSubmit: performLogin
        }}
        formProps={{
            className: 'bo_login-form'
        }}
    >
        <CMFTextInput id={'username'} name={'username'} placeholder={'username'}/>
        <CMFPasswordInput id={'password'} name={'password'}/>
        <Button type={'submit'} text={'submit'}/>
        {showError && <Alert severity="error">{errorMessage}</Alert>}
    </CustomForm>);
}
