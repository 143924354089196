import {Button} from '@boschung/common-react-components';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useTrailManager} from "../../utils/hooks/data/providers/trail";
import {isNullOrUndefined} from "../../utils/toolbox";
import {Select} from "../tmp/select";

export function PositionSelect() {
    const {t} = useTranslation();
    const {
        positions, selectedPosition, setSelectedPosition, selectNextPosition, selectPreviousPosition
    } = useTrailManager();

    const [sp, setSp] = useState(null);
    useEffect(() => {
        if (!isNullOrUndefined(selectedPosition)) {
            setSp(positionToOption(selectedPosition));
        } else {
            setSp(null);
        }
    }, [selectedPosition]);

    function positionToOption(p) {
        return {id: p.sequenceNumber, name: 'position_' + p.sequenceNumber};
    }

    return (<div className={'flex-container__column expand'}>
        <div className={'flex-container__row center'}>
            <Button style={{margin: 12}} onClick={selectPreviousPosition} text={'<<<'}/>
            <Select
                label={t('form_positions_position_select')}
                setSelectedData={p => setSelectedPosition(positions.find(_p => _p.sequenceNumber === p.id))}
                selectedData={sp}
                data={positions.map(positionToOption)}
            />
            <Button style={{margin: 12}} onClick={selectNextPosition} text={'>>>'}/>
        </div>
        {selectedPosition && (<div className={'flex-container__row center'}>
            <p className={'text left'}
               style={{textAlign: 'center'}}>{t('lat')} : {selectedPosition.latitude}</p>
            <p className={'text right'}
               style={{textAlign: 'center'}}>{t('lng')} : {selectedPosition.longitude}</p>
        </div>)}
    </div>);
}
