import {Button} from "@boschung/common-react-components";
import {Circle, HomeRepairService} from "@mui/icons-material";
import {List} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {Activity, ActivityOption} from "../../../../../types/beemove-masterdata";
import {useMasterdataManager} from "../../../../utils/hooks/data/providers/masterdata";
import {hasData, isNullOrUndefined} from "../../../../utils/toolbox";
import {
    BasicModal, BasicModalContent, BasicModalFooter, BasicModalHeader, Props as ModalProps
} from "../../../modal/basic-modal";
import {ListItem} from "../../../tmp/lists/list-item";

interface Props extends ModalProps {
    onActivitySelected(data: { activityId: Activity['id'], option: ActivityOption['position'] }): void;
}

export function ActivitiesModal({onActivitySelected, setShowModal}: Props) {
    const {t} = useTranslation();
    const {activities} = useMasterdataManager();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const newCategories = new Map();
        activities.forEach(activity => {
            const category = activity.activityCategory;
            if (newCategories.has(category.id)) {
                newCategories.get(category.id).activities.push(activity);
            } else {
                newCategories.set(category.id, {
                    ...category, activities: [activity]
                });
            }
        });
        setCategories(Array.from(newCategories.values()));
    }, [activities]);

    function selectActivity(activity: Activity, option: ActivityOption) {
        onActivitySelected({
            activityId: activity.id, option: option?.position
        });
        setShowModal(false);
    }

    function renderOptions(activity) {
        return activity.activityOptions
            .map((option: ActivityOption) => {
                return (<div className={'flex-container__row center expand option'}>
                    <div className={'option__left'}>
                        <Button
                            text={option.icon}
                            onClick={() => selectActivity(activity, option)}
                        />
                    </div>
                    <div className={'option__right'}>
                        <p>{option.name}</p>
                    </div>
                </div>);
            });
    }

    function renderActivity(activity) {
        return (<ListItem
            icon={<HomeRepairService sx={{fill: '#ffcc00'}}/>}
            text={activity.name}
            level={1}
            children={hasData(activity.activityOptions) ? (renderOptions(activity)) : ([<div
                className={'flex-container expand full-width center'}>
                <Button
                    className={'half-width'}
                    text={t('modal_activities_select')}
                    onClick={() => selectActivity(activity, null)}
                />
            </div>])}
        />)
    }

    return (<BasicModal setShowModal={setShowModal}>
        <BasicModalHeader>
            <h1>{t('cron_modal_title')}</h1>
        </BasicModalHeader>
        <BasicModalContent>
            {hasData(categories) && (<List component="nav" style={{maxHeight: '50vh', overflow: 'auto'}}>
                {categories.map(category => {
                    return (<ListItem
                        icon={<Circle sx={{fill: category.color}}/>}
                        text={category.name}
                        children={category.activities
                            // @ts-ignore FIXME: V1, we hide activities with report
                            .filter((activity: Activity) => isNullOrUndefined(activity.activityReportTemplate))
                            .map(renderActivity)}
                    />);
                })}
            </List>)}
        </BasicModalContent>
        <BasicModalFooter>
            <Button
                type={'button'}
                text={t('cancel')}
                onClick={() => setShowModal(false)}
            />
        </BasicModalFooter>
    </BasicModal>)
}

export default withTranslation()(ActivitiesModal);
