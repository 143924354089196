import {Center, Organisation} from "../../../types/beemove-masterdata";
import {URL_SERVICES_BMOVES_MASTERDATA} from "../../constants/urls";
import {AuthState} from "../../utils/hooks/auth";
import {get} from "../api";

export const BeemoveMasterdataAPI = {
    async getCenters(auth: AuthState, organisationId: Organisation['id']): Promise<Center[] | null> {
        return await get(auth, URL_SERVICES_BMOVES_MASTERDATA + `organisations/${organisationId}/centers/cascaded?enabled=true`);
    },

    // TODO: Types
    async getConsumables(auth: AuthState, organisationId: Organisation['id']) {
        return await get(auth, URL_SERVICES_BMOVES_MASTERDATA + `organisations/${organisationId}/consumableitems?enabled=true`);
    },

    // TODO: Types
    async getActivities(auth: AuthState, organisationId: Organisation['id']) {
        return await get(auth, URL_SERVICES_BMOVES_MASTERDATA + `organisations/${organisationId}/activities?enabled=true`);
    },

    // TODO: Types
    async getStatuses(auth: AuthState, organisationId: Organisation['id']) {
        return await get(auth, URL_SERVICES_BMOVES_MASTERDATA + `organisations/${organisationId}/status?enabled=true`);
    },
}
