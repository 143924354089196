import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
import React from 'react';
import {hasData} from "../../../utils/toolbox";


export function ListItem({icon, text, content, level, children}) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    return (<React.Fragment>
        <ListItemButton onClick={handleClick} sx={{pl: 4 * level}}>
            {icon && (<ListItemIcon>
                {icon}
            </ListItemIcon>)}
            {text && (<ListItemText primary={text}/>)}
            {content}
            {hasData(children) && (open ? <ExpandLess/> : <ExpandMore/>)}
        </ListItemButton>
        {hasData(children) && (<Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {children}
            </List>
        </Collapse>)}
    </React.Fragment>)
}

ListItem.defaultProps = {
    level: 0
}

ListItem.propTypes = {
    icon: PropTypes.object,
    text: PropTypes.string,
    content: PropTypes.object,
    level: PropTypes.number,
    children: PropTypes.array
}
