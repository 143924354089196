/* global google */

import React, {useEffect, useState} from 'react';
import {useBlueprintManager} from "../../utils/hooks/data/providers/blueprint";
import {useTrailManager} from "../../utils/hooks/data/providers/trail";
import {useGoogleMaps} from "../../utils/hooks/googleMaps";
import {hasData, isNullOrUndefined} from "../../utils/toolbox";
import {Loading} from "../loading/loading";

interface Props {
    defaultZoom: number;
    defaultCenter: google.maps.LatLngLiteral
}

export function CustomGoogleMaps({
    defaultZoom, defaultCenter
}: Props) {
    const {
        map, addMarker, removeMarkers, addPolyline, removePolylines
    } = useGoogleMaps(defaultZoom, defaultCenter, () => {
    });
    const {selectedBlueprint} = useBlueprintManager();
    const {selectedPosition, positions, lines} = useTrailManager();
    const [marker, setMarker] = useState(null);

    useEffect(() => {
        if (isNullOrUndefined(selectedPosition)) return;
        if (isNullOrUndefined(marker)) {
            setMarker(addMarker(selectedPosition.latitude, selectedPosition.longitude));
        } else {
            marker.setPosition(new google.maps.LatLng(selectedPosition.latitude, selectedPosition.longitude));
        }
    }, [selectedPosition]);

    function zoomOnTrail() {
        if (!isNullOrUndefined(map) && hasData(positions)) {
            map.fitBounds(positions.reduce((
                bounds,
                p
            ) => bounds.extend(new google.maps.LatLng(p.latitude, p.longitude)), new google.maps.LatLngBounds()));
        }
    }

    useEffect(() => {
        const timeout = setTimeout(zoomOnTrail, 100);
        return () => clearTimeout(timeout);
    }, [positions]);

    useEffect(() => {
        if (isNullOrUndefined(map)) return;

        // Remove marker
        removeMarkers();
        removePolylines();
        setMarker(null);

        // TODO: Add marker for the start/end coordinate?
    }, [selectedBlueprint]);

    // Draw the trail from useTrailManager
    useEffect(() => {
        removePolylines();
        lines.forEach(addPolyline);
    }, [lines]);

    return (<React.Fragment>
        {isNullOrUndefined(map) && <Loading/>}
        <div id={"map"} className={'map'} style={{
            width: isNullOrUndefined(map) ? 0 : '100%', height: isNullOrUndefined(map) ? 0 : '100%'
        }}/>
    </React.Fragment>);
}
