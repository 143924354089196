/* global google */

import {useEffect, useState} from "react";
import {apiKey, apiLibraries, apiVersion} from "../../constants/googleMaps";

export interface GoogleMapsState {
    map: google.maps.Map,

    addMarker(lat: number, lng: number): void,

    removeMarkers(): void,

    addPolyline(options: google.maps.PolylineOptions): void,

    removePolylines(): void
}

export const useGoogleMaps = (defaultZoom: number,
    defaultCenter: google.maps.MapOptions['center'],
    onload: () => void
): GoogleMapsState => {
    // --- Vars and state --- //
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
    const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);

    // Add script to the page
    useEffect(() => {
        // Do not include the script once more if present
        // FIXME: if (typeof google === 'object' && typeof google.maps === 'object') return;
        let apiUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
        if (apiLibraries) apiUrl += `&libraries=${apiLibraries}`;
        if (apiVersion) apiUrl += `&v=${apiVersion}`;
        const script = document.createElement('script');

        // Config script
        script.src = apiUrl;
        script.async = true;

        document.body.appendChild(script);
        return () => {
            setMap(null);
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        return () => {
            removeMarkers();
            removePolylines();
        }
    }, [map]);

    // @ts-ignore
    window.initMap = () => {
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: defaultZoom, center: defaultCenter, disableDefaultUI: true, zoomControl: true
        });
        map.addListener("idle", () => {
            if (typeof onload === 'function') onload();
        });
        setMap(map);
    };

    function addMarker(lat: number, lng: number) {
        const marker = new google.maps.Marker({
            position: {lat, lng}, map
        });
        markers.push(marker);
        console.debug("googleMaps: added marker", marker);
        return marker;
    }

    function removeMarkers() {
        if (markers && markers.length) {
            console.debug("googleMaps: removing markers", markers.length);
            markers.forEach(marker => marker.setMap(null));
            markers.length = 0;
            console.debug("googleMaps: removed markers", markers.length);
        }
    }

    /* TODO: https://developers.google.com/maps/documentation/javascript/react-map
     *	- AddMarker
     * 	- RemoveMarker
     * 	- GetMarker
     */

    function addPolyline(polylineOptions: google.maps.PolylineOptions) {
        console.debug("googleMaps: adding polyline", polylines.length, polylineOptions);
        const polyline = new google.maps.Polyline({
            ...polylineOptions, map
        });
        polylines.push(polyline);
        console.debug("googleMaps: added polyline", polylines.length, polyline);
        return polyline;
    }

    function removePolylines() {
        if (polylines && polylines.length) {
            console.debug("googleMaps: removing polylines", polylines.length);
            polylines.forEach(polyline => polyline.setMap(null));
            polylines.length = 0;
            console.debug("googleMaps: removed polylines", polylines.length);
        }
    }

    return {
        map, addMarker, removeMarkers, addPolyline, removePolylines
    };
}
