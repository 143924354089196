import {Button} from "@boschung/common-react-components";
import {Box} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import '../../../../select/activities/modal/style.scss';
import {MUIStyle, MUITableStyle} from "../../../../../../styles/mui";
import {MeasureSource} from "../../../../../../types/beemove-intervention";
import {Equipment} from "../../../../../../types/beemove-masterdata";
import {InterventionPosition} from "../../../../../../types/intervention-simulator";
import {useConfigManager} from "../../../../../utils/hooks/data/providers/config";
import {useTrailManager} from "../../../../../utils/hooks/data/providers/trail";
import {hasData, isNullOrUndefined} from "../../../../../utils/toolbox";
import {OkCancelModal} from "../../../../modal/ok-cancel-modal";
import {EquipmentSelect} from "../../../../select/equipment/equipment-select";
import {DenseTable} from "../../../../tables/dense-table";
import {Select} from "../../../../tmp/select";

function getRandomIndex(array) {
    return Math.floor(array.length * Math.random());
}

function getRandomItem(array) {
    return array[getRandomIndex(array)];
}

function generateRandomMeasure(equipment: Equipment, source: MeasureSource) {
    const measurands = equipment.measurands;
    const measurand = getRandomItem(measurands);

    let value = null;
    switch (measurand.type) {
        case "TOGGLE":
            value = Math.random() < 0.5 ? 0 : 1;
            break;
        case "NUMERIC":
            const {min, max} = measurand.measurandNumeric;
            value = Math.floor((max - min) * Math.random());
            break;
        case "LIST":
            const items = measurand.measurandListItems;
            value = getRandomItem(items).value // FIXME: Maybe the id should be used instead
            break;
        case "COUNTER":
            value = Math.random();
            break;
    }

    return ({
        measurandId: measurand.id, equipmentId: equipment.id, value, source
    });
}

export function EquipmentForm() {
    const {t} = useTranslation();
    const {selectedEquipment: configEquipment} = useConfigManager();
    const {selectedPosition, positions, updateTrail} = useTrailManager();

    const [selectedEndPosition, setSelectedEndPosition] = useState(null);
    const [selectedEquipment, setSelectedEquipment] = useState(null);

    function addEquipment(
        startPositionSequenceNumber: InterventionPosition['sequenceNumber'],
        stopPositionSequenceNumber: InterventionPosition['sequenceNumber'],
        equipment: Equipment
    ) {
        const spsn = Math.min(startPositionSequenceNumber - 1, stopPositionSequenceNumber);
        const epsn = Math.max(startPositionSequenceNumber - 1, stopPositionSequenceNumber);
        positions.slice(spsn, epsn)
            .forEach(position => {
                position.measures.push(generateRandomMeasure(equipment, 'manual'))
            });
        // Update trail
        updateTrail();
    }

    function remove(array, callback) {
        let i = 0;
        while (i < array.length) {
            if (callback(array[i], i)) {
                array.splice(i, 1);
            } else {
                ++i;
            }
        }
    }

    function _iterateAndRemoveMeasure(equipmentId, start, condition, reverse) {
        for (let i = start; condition(i); i += (reverse ? -1 : 1)) {
            const position = positions[i];
            if (hasData(position.measures)) {
                remove(position.measures, measure => measure.equipmentId === equipmentId);
            }
        }
    }

    /**
     * Removes all measures for this equipment from the selected position and <-- -->
     * @param equipmentId
     */
    function removeMeasure(equipmentId: Equipment['id']) {
        _iterateAndRemoveMeasure(equipmentId, selectedPosition.sequenceNumber - 1, i => i < positions.length, false);
        _iterateAndRemoveMeasure(equipmentId, selectedPosition.sequenceNumber - 1, i => i >= 0, true);

        // Update trail
        updateTrail();
    }

    const [sp, setSp] = useState(null);
    useEffect(() => {
        if (!isNullOrUndefined(selectedEndPosition)) {
            setSp(positionToOption(selectedEndPosition));
        } else {
            setSp(null);
        }
    }, [selectedEndPosition]);

    function positionToOption(p) {
        return {id: p.sequenceNumber, name: 'position_' + p.sequenceNumber};
    }

    function onEquipmentSelected(equipment: Equipment) {
        setSelectedEquipment(equipment);
    }

    // Confirmation modal
    const [showModal, setShowModal] = useState(false);
    const [modalValueId, setModalValueId] = useState(null);

    function _openModal(id) {
        setModalValueId(id);
        setShowModal(true);
    }

    function _onOk() {
        removeMeasure(modalValueId);
        setShowModal(false);
    }

    function _onCancel() {
        setShowModal(false);
    }

    // Render form
    const items = hasData(selectedPosition?.measures) ? selectedPosition?.measures.map(({
        equipmentId, measurandId, value
    }) => {
        return Object.assign({}, configEquipment.find(e => e.id === equipmentId), {value, measurandId});
    }) : [];
    return (<Box sx={MUIStyle} className={'flex-container__column expand'}>
        <Box className={'expand'} sx={MUITableStyle}>
            <DenseTable
                itemCountPerPage={5}
                headers={['name', 'measurandId', 'value']}
                translations={{'name': 'Equipment'}}
                items={items}
                actions={(equipment) => [{
                    iconName: 'delete', callback: () => _openModal(equipment.id)
                }]}
            />
            {showModal && (<OkCancelModal
                title={t('modal_confirm_title')}
                content={t('modal_confirm_body')}
                onOk={_onOk}
                onCancel={_onCancel}
            />)}
        </Box>
        {selectedPosition && (<div className={'flex-container__column shrink'}>
            <div className={'flex-container__row center'}>
                <EquipmentSelect // FIXME
                    label={t('__select__equipment__')}
                    selectedEquipment={selectedEquipment}
                    onEquipmentSelected={onEquipmentSelected}
                />
            </div>
            <div className={'flex-container__row center'}>
                <Select
                    label={t('form_activities_to')}
                    data={positions.map(positionToOption)}
                    setSelectedData={p => setSelectedEndPosition(positions.find(_p => _p.sequenceNumber === p.id))}
                    selectedData={sp}
                />
            </div>
            <Button
                text={t('__add_measure__')}
                onClick={() => addEquipment(selectedPosition.sequenceNumber, selectedEndPosition.sequenceNumber, selectedEquipment)}
            />
        </div>)}
    </Box>);
}
