import React, {createContext, useContext} from "react";
import {BeemoveMasterdataState, useProvideMasterdataManager} from "../masterdata";

const DataContext = createContext<BeemoveMasterdataState>(undefined);

export function ProvideMasterdataManager({children}) {
    const dataManager = useProvideMasterdataManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useMasterdataManager = () => {
    return useContext(DataContext);
};
