import React from 'react';
import {useTranslation} from "react-i18next";
import {useConfigManager} from "../../utils/hooks/data/providers/config";
import {isNullOrUndefined} from "../../utils/toolbox";
import {Loading} from "../loading/loading";
import {Select} from "../tmp/select";

export function VehicleSelect() {
    const {t} = useTranslation();
    const {selectedConfig, selectedCenter, selectedVehicle, setSelectedVehicle} = useConfigManager();
    return (!Number(selectedConfig?.vehicle?.id) || !isNullOrUndefined(selectedVehicle)) ? (<Select
        label={t('select_vehicles')}
        setSelectedData={setSelectedVehicle}
        selectedData={selectedVehicle}
        data={selectedCenter.vehicles}
    />) : <Loading/>;
}
