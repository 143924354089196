import React, {useEffect} from 'react';
import {ErrorBoundary} from "react-error-boundary";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {Loading} from "./components/loading/loading";
import {AppModule} from "./components/modules/app/app-module";
import {LoginModule} from "./components/modules/login/login-module";
import {modules} from "./constants/modules";
import {ProvideBlueprintManager} from "./utils/hooks/data/providers/blueprint";
import {ProvideConfigManager} from "./utils/hooks/data/providers/config";
import {ProvideMasterdataManager} from "./utils/hooks/data/providers/masterdata";
import {ProvideSimulatorManager} from "./utils/hooks/data/providers/simulator";
import {ProvideTrackManager} from "./utils/hooks/data/providers/tracks";
import {ProvideTrailManager} from "./utils/hooks/data/providers/trail";
import {useAuth} from "./utils/hooks/providers/auth";
import {ProvideOrganisationManager} from "./utils/hooks/providers/organisation";
import {isNullOrUndefined} from "./utils/toolbox";

export function App() {
    const {
        autologin, isAuthenticated, logout
    } = useAuth();

    // Auto-login
    useEffect(() => {
        autologin()
            .then(r => {
            })
            .catch(e => {
                console.error(e);
            });
    }, []);

    // Router
    const history = useHistory();
    const location = useLocation();

    // Module
    const loadedModule = isAuthenticated ? modules.app : modules.login;

    useEffect(() => {
        if (!isNullOrUndefined(loadedModule)) {
            const isRouteUnknown = isNullOrUndefined(loadedModule.routes[location.pathname.slice(1)]);
            if (isRouteUnknown) {
                history.push(loadedModule.redirect);
            }
        }
    }, [location, isAuthenticated]);

    function wrapper(children: React.JSX.Element[]) {
        return isAuthenticated ? (<ProvideOrganisationManager>
            <ProvideMasterdataManager>
                <ProvideConfigManager>
                    <ProvideSimulatorManager>
                        <ProvideTrackManager>
                            <ProvideBlueprintManager>
                                <ProvideTrailManager>
                                    <AppModule>
                                        {children}
                                    </AppModule>
                                </ProvideTrailManager>
                            </ProvideBlueprintManager>
                        </ProvideTrackManager>
                    </ProvideSimulatorManager>
                </ProvideConfigManager>
            </ProvideMasterdataManager>
        </ProvideOrganisationManager>) : (<LoginModule>
            {children}
        </LoginModule>);
    }

    function ErrorFallback({error, resetErrorBoundary}) {
        return (<div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>);
    }

    return (<React.Fragment>
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={logout}
        >
            {Boolean(loadedModule) ? (<Switch>
                {wrapper(Object.entries(loadedModule.routes)
                    .map(([routeName, routeArgs]) => <Route
                        exact={true}
                        key={routeName}
                        {...routeArgs}
                    />))}
            </Switch>) : (<Loading/>)}
        </ErrorBoundary>
    </React.Fragment>);
}
