import React, {createContext, useContext} from "react";
import {ConfigState, useProvideConfigManager} from "../config";

const DataContext = createContext<ConfigState>(undefined);

export function ProvideConfigManager({children}) {
    const dataManager = useProvideConfigManager();
    return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useConfigManager = () => {
    return useContext(DataContext);
};
