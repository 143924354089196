import {Button} from '@boschung/common-react-components';
import React from 'react';
import {useTranslation} from "react-i18next";
import {BasicModal, BasicModalContent, BasicModalFooter, BasicModalHeader} from "./basic-modal";

interface Props {
    title?: string;
    content?: string;
    okText?: string;
    cancelText?: string;

    onOk(): void;

    onCancel(): void;
}

export function OkCancelModal({title, content, onOk, onCancel, okText, cancelText}: Props) {
    const {t} = useTranslation();
    return (// @ts-ignore FIXME
        <BasicModal setShowModal={() => {
        }}>
            {title && <BasicModalHeader>{title}</BasicModalHeader>}
            {content && <BasicModalContent>{content}</BasicModalContent>}
            <BasicModalFooter>
                <Button
                    className={'expand'}
                    text={okText ?? t('ok')}
                    onClick={onOk}
                    buttonStyle={'primary'}
                />
                <Button
                    className={'expand'}
                    text={cancelText ?? t('cancel')}
                    onClick={onCancel}
                    buttonStyle={'secondary'}
                />
            </BasicModalFooter>
        </BasicModal>);
}
