import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom";
import {App} from './app/App';
import {I18nManager} from "./app/i18n/i18n-manager";
import {ProvideAuth} from "./app/utils/hooks/providers/auth";

import './styles/main.scss';

ReactDOM.render(<I18nManager lang={'en'}>
    <ProvideAuth>
        <HashRouter>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </HashRouter>
    </ProvideAuth>
</I18nManager>, document.getElementById('app'));
