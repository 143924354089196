import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';

const SIZE_SMALL = 'sm';
const SIZE_MEDIUM = 'md';
const SIZE_BIG = 'lg';

const sizes = [SIZE_SMALL, SIZE_MEDIUM, SIZE_BIG];

const getSizePx = size => {
    switch (size) {
        case SIZE_SMALL:
            return 30;
        case SIZE_MEDIUM:
            return 50;
        case SIZE_BIG:
            return 80;
        default:
            return 50;
    }
};

const getFontSize = size => {
    const dfSize = 16;
    let sizeValue = dfSize;
    switch (size) {
        case SIZE_SMALL:
            sizeValue = 12;
            break;
        case SIZE_MEDIUM:
            sizeValue = 16;
            break;
        case SIZE_BIG:
            sizeValue = 20;
            break;
        default:
            sizeValue = dfSize;
    }
    return {fontSize: sizeValue};
};

interface Props {
    color: string;
    size: number;
    text?: string
}

export const Loading = (props: Props) => {
    const {color, size, text} = props;
    const sizePx = getSizePx(size);
    const fontSize = getFontSize(size);

    return (<div className="loading">
        <ReactLoading
            type="spinningBubbles"
            height={sizePx}
            width={sizePx}
            color={color}
        />
        {text && (<h3 className="loading__text" style={fontSize}>
            {text}
        </h3>)}
    </div>);
};
Loading.defaultProps = {
    color: '#FFCC00', size: SIZE_MEDIUM
};

Loading.propTypes = {
    /** Hexa icon color */
    color: PropTypes.string, /** Icon size */
    size: PropTypes.oneOf(sizes), /** Loading text */
    text: PropTypes.string
};
