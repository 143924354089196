import {Button} from '@boschung/common-react-components';
import {Box} from "@mui/material";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {MUIStyle} from "../../../../../styles/mui";
import {useConfigManager} from "../../../../utils/hooks/data/providers/config";
import {useMasterdataManager} from "../../../../utils/hooks/data/providers/masterdata";
import {useOrganisationManager} from "../../../../utils/hooks/providers/organisation";
import {hasData, isNullOrUndefined} from "../../../../utils/toolbox";
import {Loading} from "../../../loading/loading";
import {OkCancelModal} from "../../../modal/ok-cancel-modal";
import {CenterSelect} from "../../../select/center-select";
import {ConfigSelect} from "../../../select/config-select";
import {EquipmentMultiSelect} from "../../../select/equipment-multi-select";
import {CoOperatorSelect} from "../../../select/operators/co-operator-select";
import {MainOperatorSelect} from "../../../select/operators/main-operator-select";
import {OperatorMultiSelect} from "../../../select/operators/operator-multi-select";
import {VehicleSelect} from "../../../select/vehicle-select";
import {ConfigModal} from "./modal/config-modal";

// CSS
import './style.scss';

export function ConfigForm() {
    const {t} = useTranslation();
    const {centers} = useMasterdataManager();
    const {selectedOrganisation} = useOrganisationManager();
    const {saveConfig, deleteConfig, selectedCenter, selectedConfig} = useConfigManager();
    const [showModal, setShowModal] = useState(false);

    function openModalCreateConfig() {
        setShowModal(true);
    }

    function deleteCurrentConfig() {
        deleteConfig(selectedConfig.id);
    }

    function renderConfigForm() {
        return hasData(centers) ? (<div className={'flex-container__column config-subform expand'}>
            <CenterSelect/>
            {selectedCenter && (<React.Fragment>
                <MainOperatorSelect/>
                <CoOperatorSelect/>
                <OperatorMultiSelect/>
                <VehicleSelect/>
                <EquipmentMultiSelect/>
                <Button
                    className={'config-save'}
                    onClick={saveConfig}
                    text={t('form_config_btn_save')}
                />
            </React.Fragment>)}
        </div>) : <Loading/>;
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    function _onOk() {
        deleteCurrentConfig();
        setShowConfirmModal(false);
    }

    function _onCancel() {
        setShowConfirmModal(false);
    }

    function renderConfigSelect() {
        if (isNullOrUndefined(selectedOrganisation)) {
            return <p>{t('no_organisation_selected')}</p>;
        }

        // Config from
        return (<div className={'flex-container__column config-subform expand'}>
            <ConfigSelect/>
            <div className={'flex-container__row center'}>
                <Button
                    buttonSize={'sm'}
                    className={'config-button'}
                    iconName={'add'}
                    text={t('form_config_btn_new')}
                    onClick={openModalCreateConfig}
                />
                <Button
                    buttonSize={'sm'}
                    className={'config-button'}
                    iconName={'delete'}
                    text={t('form_config_btn_delete')}
                    onClick={() => setShowConfirmModal(true)}
                />
            </div>
            {selectedConfig && (<React.Fragment>
                <div style={{margin: '0.625em'}}/>
                {renderConfigForm()}
            </React.Fragment>)}
            {showConfirmModal && (<OkCancelModal
                title={t('modal_confirm_title')}
                content={t('modal_confirm_body')}
                onOk={_onOk}
                onCancel={_onCancel}
            />)}
        </div>);
    }

    return (<Box sx={MUIStyle} className={'config-form'}>
        {renderConfigSelect()}
        {showModal && <ConfigModal setShowModal={setShowModal}/>}
    </Box>);
}
