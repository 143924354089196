import {Button} from "@boschung/common-react-components";
import {ClickAwayListener, FormControl, InputLabel, TextField} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useBlueprintManager} from "../../../../../utils/hooks/data/providers/blueprint";
import {useConfigManager} from "../../../../../utils/hooks/data/providers/config";
import {useTrackManager} from "../../../../../utils/hooks/data/providers/tracks";
import {hasData, isNullOrUndefined} from "../../../../../utils/toolbox";
import {SelectForm} from "../../../../form/select-form";
import {RadioGroup} from "../../../../tmp/radio-group";
import {Select} from "../../../../tmp/select";

export function BlueprintForm() {
    const {t} = useTranslation()
    const {selectedConfig} = useConfigManager();
    const {selectedTrack} = useTrackManager();
    const {
        selectedBlueprint, setSelectedBlueprint, blueprints, createBlueprint, deleteBlueprint
    } = useBlueprintManager();

    const [blueprintName, setBlueprintName] = useState(null);
    const [blueprintSource, setBlueprintSource] = useState(null);
    const [isBlueprintValid, setIsBlueprintValid] = useState(false);

    const [showBlueprintForm, setShowBlueprintForm] = useState(false);
    const [availableBlueprints, _setAvailableBlueprints] = useState([]);
    useEffect(() => {
        const filterBlueprint = (blueprint) => (blueprint.interventionConfigId === selectedConfig?.id) && (isNullOrUndefined(selectedTrack) || blueprint.trackId === selectedTrack.id);

        if (hasData(blueprints)) {
            _setAvailableBlueprints(blueprints.filter(filterBlueprint))
        }
    }, [blueprints, selectedConfig, selectedTrack]);

    useEffect(() => {
        setIsBlueprintValid(!isNullOrUndefined(blueprintName) && blueprintName.length > 0 && !isNullOrUndefined(blueprintSource) && !isNullOrUndefined(selectedConfig) && !isNullOrUndefined(selectedTrack));
    }, [blueprintName, blueprintSource, selectedConfig, selectedTrack]);

    function handleBlueprintNameChange(e) {
        setBlueprintName(e.target.value.trim());
    }

    function handleBlueprintSourceChange(e, value) {
        setBlueprintSource(value);
    }

    function createBlueprintOnServices() {
        createBlueprint(blueprintName, blueprintSource);
        setShowBlueprintForm(false);
    }

    function deleteCurrentBlueprint() {
        if (!isNullOrUndefined(selectedBlueprint)) {
            deleteBlueprint(selectedBlueprint.id);
        }
    }

    function renderBlueprintUploadForm() {
        return (<ClickAwayListener onClickAway={() => setShowBlueprintForm(false)}>
            <div className={'flex-container__column card'}>
                <FormControl>
                    <InputLabel htmlFor={'interventionName'}/>
                    <TextField
                        id={'blueprintName'}
                        name={'blueprintName'}
                        label={t('form_blueprint_blueprint_name')}
                        value={blueprintName}
                        onChange={handleBlueprintNameChange}
                        variant={'standard'}
                    />
                </FormControl>
                <RadioGroup
                    className={'center card-mini'}
                    name={'blueprintSource'}
                    label={t('form_blueprint_blueprint_source')}
                    orientation={'horizontal'}
                    onChange={handleBlueprintSourceChange}
                    options={['bMoves', "vpad", "vpad2xplus"]}
                />
                <Button
                    disabled={!isBlueprintValid}
                    text={t('form_blueprint_save')}
                    onClick={createBlueprintOnServices}
                />
            </div>
        </ClickAwayListener>)
    }

    return (<div className={'flex-container__column'}>
        <SelectForm
            onAddClick={() => setShowBlueprintForm(true)}
            onDeleteClick={deleteCurrentBlueprint}
            isAddDisabled={isNullOrUndefined(selectedConfig) || isNullOrUndefined(selectedTrack)}
            isDeleteDisabled={isNullOrUndefined(selectedBlueprint)}
        >
            <Select
                label={t("select_blueprints")}
                data={availableBlueprints}
                setSelectedData={setSelectedBlueprint}
                selectedData={selectedBlueprint}
            />
        </SelectForm>
        {showBlueprintForm && renderBlueprintUploadForm()}
    </div>);
}
