import {Mutex} from 'async-mutex';
import jwtDecode from "jwt-decode";
import {useEffect, useRef, useState} from "react";
import {OAuthToken} from "../../../types/auth";
import {Organisation} from "../../../types/beemove-masterdata";
import auth from "../../api/common/auth";
import {KSS_ACCESS_TOKEN, KSS_REFRESH_TOKEN} from "../../constants/storage/keys";
import {getCurrentTimestamp} from "../date";
import {isNullOrUndefined} from "../toolbox";
import {useStateWithSessionStorage} from "./storage";

export interface AuthState {
    isAuthenticated: boolean,
    organisationId: Organisation['id'],
    firstName: OAuthToken['firstName'],
    lastName: OAuthToken['lastName'],

    // Methods
    autologin(): Promise<boolean>,

    authenticate(username: string, password: string): Promise<boolean>,

    logout(): void,

    getAccessToken(): Promise<string | null>
}

const TOKEN_DELAY = 60; // Seconds
const TOKEN_TTL = 55 * 60 * 1000; // milliseconds
const AUTHORISED_ROLE = 'is-x_simulator';

const mutex = new Mutex();

// Provider hook that creates auth object and handles state
export function useProvideAuth(): AuthState {
    const [accessToken, setAccessToken] = useStateWithSessionStorage(KSS_ACCESS_TOKEN, null);
    const [refreshToken, setRefreshToken] = useStateWithSessionStorage(KSS_REFRESH_TOKEN, null);

    // --- Local state --- //
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [organisationId, setOrganisationId] = useState<Organisation['id']>(null);
    const [firstName, setFirstName] = useState<string>(null);
    const [lastName, setLastName] = useState<string>(null);
    // deviceId, alternativeDeviceId, authorities, language, userId, user_name

    // --- Refresh access token process --- //
    const refresherRef = useRef(null);
    const accessTokenRef = useRef(accessToken); // https://felixgerschau.com/react-hooks-settimeout/
    const refreshTokenRef = useRef(refreshToken);

    useEffect(() => {
        accessTokenRef.current = accessToken;
        console.debug("Auth => Access token updated…");
    }, [accessToken]);
    useEffect(() => {
        refreshTokenRef.current = refreshToken;
        console.debug("Auth => Refresh token updated…");
    }, [refreshToken]);


    useEffect(() => {
        const isAuthorized = isAccessTokenValid(accessTokenRef.current);
        setIsAuthenticated(isAuthorized);
        if (isAuthorized) {
            const tokenInfo: OAuthToken = jwtDecode(accessTokenRef.current);
            setOrganisationId(tokenInfo.organisationId);
            setFirstName(tokenInfo.firstName);
            setLastName(tokenInfo.lastName);
        }
    }, [accessTokenRef.current]);

    function returnNewRefresher() {
        return setTimeout(async function () {
            await _refreshAccessToken();
            clearTimeout(refresherRef.current);
            refresherRef.current = returnNewRefresher();
        }, TOKEN_TTL);
    }

    // Toggle refresher
    useEffect(() => {
        if (isAuthenticated) {
            refresherRef.current = returnNewRefresher();
        } else {
            clearTimeout(refresherRef.current);
        }
        return () => {
            clearTimeout(refresherRef.current);
        }
    }, [isAuthenticated]);

    function _handleNewTokens(at: any, rt: any) {
        if (isAccessTokenValid(at) && isRefreshTokenValid(rt)) {
            setAccessToken(at);
            setRefreshToken(rt);
            return at;
        }
        return null;
    }

    /**
     * Tries to refresh the accessToken
     * @return {Promise<null|*>} the accessToken or null in case of failure
     * @private
     */
    async function _refreshAccessToken(): Promise<null | any> {
        console.debug("Auth => Refreshing access token…");
        if (isRefreshTokenValid(refreshTokenRef.current)) {
            const [at, rt] = await auth.refreshAccessToken(refreshTokenRef.current);
            return _handleNewTokens(at, rt);
        }

        // Operation failed
        console.debug("Auth => Failed to refresh the access token…");
        logout();
        return null;
    }

    /**
     * Retrieve the access token from either the KeyValueStorage or the server.
     * It will first try to look it up in the KeyValueStorage but if the found
     * token is expired it will use the refresh token to refresh it from the
     * server and re-save it.
     * @returns {Promise<String|null>} the access token or null if none was found
     */
    async function getAccessToken(): Promise<string | null> {
        console.debug("Auth => Retrieving access token…");

        if (isAccessTokenValid(accessTokenRef.current)) {
            console.debug("Auth => Retrieved access token successfully…");
            return accessTokenRef.current;
        } else if (isRefreshTokenValid(refreshTokenRef.current)) {
            console.debug("Auth => Access token has expired…");
            return await _refreshAccessToken();
        }

        console.error("Auth => Access token could not be retrieved…");
        return null;
    }

    async function _autologin(n: number): Promise<boolean> {
        console.debug('Auth => Trying to auto-login…');
        if (isAccessTokenValid(accessTokenRef.current)) {
            console.debug('Auth => Auto-login successful…');
            return true;
        } else {
            if (isNullOrUndefined(await _refreshAccessToken())) {
                if (n < 3) {
                    console.debug(`Auth => Trying to refresh the token: (${n})…`);
                    return _autologin(n + 1);
                }
            } else {
                console.debug('Auth => Auto-login successful…');
                return true;
            }
        }

        // Autologin failed
        console.debug('Auth => Auto-login failed…');
        logout();
        return false;
    }

    /**
     * Try to authenticate on the server.
     * Save the access and refresh tokens on the KeyValueStorage if succeed.
     * @returns {Promise<Boolean>} true if and only if the authentication succeed
     */
    async function autologin(): Promise<boolean> {
        return _autologin(0);
    }

    /**
     * Try to authenticate on the server.
     * Save the access and refresh tokens on the KeyValueStorage if succeeded.
     * @param {string} username the authentication username
     * @param {string} password the authentication password
     * @returns true if and only if the authentication succeed
     */
    async function authenticate(username: string, password: string): Promise<boolean> {
        console.debug('Auth => Trying to login…');
        const release = await mutex.acquire();
        try {
            const [at, rt] = await auth.authenticate(username, password);
            if (isNullOrUndefined(_handleNewTokens(at, rt))) {
                logout();
                return false;
            } else {
                return true;
            }
        } catch (e) {
            // Error occurred
            console.error('Auth => Login failed…', e);
            return false;
        } finally {
            release();
        }
    }

    /**
     * Remove the access and refresh tokens from the KeyValueStorage.
     * Disable the user in the database.
     */
    function logout() {
        console.debug("Auth: logout…")
        setAccessToken(null);
        setRefreshToken(null);
        setIsAuthenticated(false);
    }

    /**
     * Retrieve the refresh token. This function gets the refresh the token,
     *  if the token is still valid, return it, if not, return null
     */
    function isRefreshTokenValid(rt: string) {
        return Boolean(rt) && (// @ts-ignore
            jwtDecode(rt).exp > (getCurrentTimestamp() + TOKEN_DELAY));
    }

    function isAccessTokenValid(at: string) {
        if (Boolean(at)) {
            const decodedAccessToken = jwtDecode(at);
            return (// @ts-ignore
                decodedAccessToken.authorities.includes(AUTHORISED_ROLE) && // @ts-ignore
                decodedAccessToken.exp > (getCurrentTimestamp() + TOKEN_DELAY));
        } else return false;
    }

    // Return the user object and auth methods
    return {
        isAuthenticated, organisationId, firstName, lastName, // Methods
        autologin, authenticate, logout, getAccessToken
    };
}
