import React, {createContext, PropsWithChildren, useContext} from "react";
import {AuthState, useProvideAuth} from "../auth";

const AuthContext = createContext<AuthState>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({children}: PropsWithChildren) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthState => {
    return useContext(AuthContext);
};
