import React, {useEffect, useState} from "react";
import {Organisation} from "../../../types/beemove-masterdata";
import {SimulatorAPI} from "../../api/intervention/simulator";
import {ORGANISATION_ID} from "../../constants/storage/keys";
import {isIterable} from "../toolbox";
import {useAuth} from "./providers/auth";
import {useStateWithLocalStorage} from "./storage";

export interface OrganisationState {
    organisations: Organisation[]
    selectedOrganisation?: Organisation | null
    setSelectedOrganisation?: React.Dispatch<Organisation>
}

// Provider hook that creates auth object and handles state
export function useProvideOrganisationManager() {
    const auth = useAuth();

    const [organisations, _setOrganisations] = useState<Organisation[]>([]);
    const [_selectedOrganisationId, _setSelectedOrganisationId] = useStateWithLocalStorage<Organisation['id']>(ORGANISATION_ID, null);
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation>({
        id: _selectedOrganisationId
    });

    function fetch() {
        SimulatorAPI.getOrganisations(auth).then(_setOrganisations);
    }

    // Boot load
    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        console.debug("Fetch: Updating available organisations…", organisations);
        if (isIterable(organisations)) {
            setSelectedOrganisation(organisations.find(organisation => organisation.id === _selectedOrganisationId));
        }
    }, [organisations]);

    useEffect(() => {
        console.debug("Fetch: Updating selected organisation…", selectedOrganisation);
        _setSelectedOrganisationId(selectedOrganisation?.id);
    }, [selectedOrganisation]);

    // Export methods
    return {
        organisations, selectedOrganisation, setSelectedOrganisation
    }
}
