import {Button} from "@boschung/common-react-components";
import React, {PropsWithChildren, useState} from "react";
import {useTranslation} from "react-i18next";
import {OkCancelModal} from "../modal/ok-cancel-modal";

interface Props {
    isAddDisabled: boolean;
    isDeleteDisabled: boolean;

    onAddClick(): void;

    onDeleteClick(): void;
}

export function SelectForm({
    onAddClick, onDeleteClick, isAddDisabled, isDeleteDisabled, children
}: PropsWithChildren<Props>) {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);

    function _onOk() {
        onDeleteClick();
        setShowModal(false);
    }

    function _onCancel() {
        setShowModal(false);
    }

    return (<div className={'flex-container__row center'}>
        <Button iconName={'add'} onClick={onAddClick} disabled={isAddDisabled}/>
        {children}
        <Button iconName={'delete'} onClick={() => setShowModal(true)} disabled={isDeleteDisabled}/>
        {showModal && (<OkCancelModal
            title={t('modal_confirm_title')}
            content={t('modal_confirm_body')}
            onOk={_onOk}
            onCancel={_onCancel}
        />)}
    </div>)
}
