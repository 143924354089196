import {Button} from '@boschung/common-react-components';
import {Box} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {MUIStyle} from "../../../../../styles/mui";
import {modules} from "../../../../constants/modules";
import {useBlueprintManager} from "../../../../utils/hooks/data/providers/blueprint";
import {useConfigManager} from "../../../../utils/hooks/data/providers/config";
import {useTrailManager} from "../../../../utils/hooks/data/providers/trail";
import {isNullOrUndefined} from "../../../../utils/toolbox";
import {SelectForm} from "../../../form/select-form";
import {CustomGoogleMaps} from "../../../maps/custom-google-maps";
import {ConfigSelect} from "../../../select/config-select";
import {PositionSelect} from "../../../select/position-select";
import {ActivitiesForm} from "./forms/activities-form";
import {BlueprintForm} from "./forms/blueprint-form";
import {EquipmentForm} from "./forms/equipment-form";
import {TrackForm} from "./forms/track-form";

// CSS
import './style.scss';


const TABS = {
    ACTIVITIES: <ActivitiesForm/>, EQUIPMENT: <EquipmentForm/>
};

export function EditorForm() {
    const {t} = useTranslation();
    const history = useHistory();

    // Data from managers
    const {selectedConfig, deleteConfig} = useConfigManager();
    const {selectedBlueprint, updateBlueprint} = useBlueprintManager();
    const {selectedPosition, positions, updateTrail} = useTrailManager();

    const [selectedTab, setSelectedTab] = useState(TABS.ACTIVITIES);

    // Boot load
    useEffect(() => {
        const timeout = setTimeout(updateTrail, 2000);
        return () => clearTimeout(timeout);
    }, []);

    function deleteCurrentConfig() {
        if (!isNullOrUndefined(selectedConfig)) {
            deleteConfig(selectedConfig.id);
        }
    }

    function renderConfig() {
        function redirectToConfig() {
            history.push(modules.app.routes.config.path);
        }

        return (<SelectForm
            onAddClick={redirectToConfig}
            onDeleteClick={deleteCurrentConfig}
            isAddDisabled={false}
            isDeleteDisabled={isNullOrUndefined(selectedConfig)}
        >
            <ConfigSelect/>
        </SelectForm>);
    }

    return (<Box className={'editor-form'} sx={MUIStyle}>
        <div className={'left'}>
            <div className={'editor-form__header'}>
                <div className={'flex-container__column expand'}>
                    {renderConfig()}
                    <TrackForm/>
                    <BlueprintForm/>
                </div>
            </div>
            <div className={'editor-form__content'}>
                <CustomGoogleMaps
                    defaultZoom={7}
                    defaultCenter={{
                        lat: 46.839547, lng: 6.917116
                    }}
                />
            </div>
            <div className={'editor-form__footer'}>
                <Button
                    disabled={isNullOrUndefined(selectedBlueprint)}
                    className={'editor-form__button'}
                    text={t('form_editor_btn_update_blueprint')}
                    onClick={() => updateBlueprint(selectedBlueprint, positions)}
                />
            </div>
        </div>
        <div className={'right'}>
            <div className={'editor-form__header'}>
                <PositionSelect/>
            </div>
            <div className={'editor-form__content'}>
                {selectedTab}
            </div>
            <div className={'editor-form__footer'}>
                {Object.entries(TABS).map(([key, value]) => (<Button
                    disabled={isNullOrUndefined(selectedPosition)}
                    className={'editor-form__button third'}
                    text={t(`editor_btn_add_${key.toLowerCase()}`)}
                    onClick={() => setSelectedTab(value)}
                />))}
            </div>
        </div>
    </Box>);
}

export default withTranslation()(EditorForm);
