export async function sleep(milliseconds: number) {
    await new Promise(r => setTimeout(r, milliseconds));
}

export function isNullOrUndefined(o) {
    return o === undefined || o === null;
}

export function isIterable(array: Array<any>) {
    return !isNullOrUndefined(array) && Array.isArray(array);
}

export function isEmpty(array: Array<any>) {
    return !Array.isArray(array) || array.length === 0;
}

export function hasData(array: Array<any>) {
    return isIterable(array) && !isEmpty(array);
}

export function removeFromArray(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}
