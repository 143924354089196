import {Button} from "@boschung/common-react-components";
import {Box, FormControl, InputLabel, TextField} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MUIStyle} from "../../../../../../styles/mui";
import {useConfigManager} from "../../../../../utils/hooks/data/providers/config";
import {isNullOrUndefined} from "../../../../../utils/toolbox";
import {
    BasicModal, BasicModalContent, BasicModalFooter, BasicModalHeader, Props as ModalProps
} from "../../../../modal/basic-modal";

export function ConfigModal({setShowModal}: ModalProps) {
    const {t} = useTranslation();
    const {createConfig} = useConfigManager();
    const [filename, setFilename] = useState(null);
    const [isFilenameValid, setIsFilenameValid] = useState(false);
    useEffect(() => {
        setIsFilenameValid(!isNullOrUndefined(filename) && filename.length > 0);
    }, [filename]);

    function handleChange(e) {
        setFilename(e.target.value.trim());
    }

    function handleClick() {
        createConfig(filename)
            .finally(() => {
                setShowModal(false);
            });
    }

    return (<BasicModal setShowModal={setShowModal}>
        <BasicModalHeader>
            <h1>{t('config_modal_title')}</h1>
        </BasicModalHeader>
        <BasicModalContent>
            <Box sx={MUIStyle}>
                <FormControl className={'expand'}>
                    <InputLabel htmlFor={'filename'}/>
                    <TextField
                        fullWidth
                        variant={'standard'}
                        id={'filename'}
                        name={'filename'}
                        label={t('form_configs_config_name')}
                        value={filename}
                        onChange={handleChange}
                    />
                </FormControl>
            </Box>
        </BasicModalContent>
        <BasicModalFooter>
            <Button
                type={'button'}
                text={t('save')}
                onClick={handleClick}
                disabled={!isFilenameValid}
            />
        </BasicModalFooter>
    </BasicModal>);
}
