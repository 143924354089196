import React from 'react';
import {useAuth} from "../../../../utils/hooks/providers/auth";
import {Loading} from "../../../loading/loading";

function Logout(props) {
    const {logout} = useAuth();
    logout();
    return <Loading/>;
}

export default Logout;
