export const interventionDtoExample = {
    "id": "ec000530-8a6f-11ec-99dd-13e368a04760",
    "centerId": 100,
    "operatorIds": [
        272
    ],
    "driverId": 210,
    "coDriverId": 296,
    "vehicleId": 75,
    "vehicle": {
        "id": 75,
        "measurandIds": [
            10012,
            10014
        ]
    },
    "equipments": [
        {
            "id": 46,
            "measurandIds": [
                1,
                10070
            ]
        }
    ],
    "startDateTime": "2022-02-10T12:49:44.595Z",
    "endDateTime": null,
    "state": "live",
    "source": "vpad",
    "isSimulated": true,
    "summary": {
        "distance": 0,
        "activities": [
            {
                "activityId": null,
                "option": null,
                "status": "inactive"
            }
        ],
        "numberOfPositions": 0,
        "minLatitude": null,
        "maxLatitude": null,
        "minLongitude": null,
        "maxLongitude": null
    },
    "instantaneous": {
        "dateTime": "2022-02-10T12:49:44.595Z",
        "speed": 0,
        "latitude": null,
        "longitude": null,
        "bearing": 0,
        "activities": [
            {
                "activityId": null,
                "option": null,
                "status": "inactive"
            }
        ],
        "measures": []
    }
};
