import {Button} from "@boschung/common-react-components";
import {List} from "@mui/material";
import React from 'react';
import {useTranslation} from "react-i18next";
import {Equipment} from "../../../../../types/beemove-masterdata";
import {useConfigManager} from "../../../../utils/hooks/data/providers/config";
import {hasData} from "../../../../utils/toolbox";
import {
    BasicModal, BasicModalContent, BasicModalFooter, BasicModalHeader, Props as ModalProps
} from "../../../modal/basic-modal";
import {ListItem} from "../../../tmp/lists/list-item";

interface Props extends ModalProps {
    onEquipmentSelected(equipment: Equipment): void
}

export function EquipmentModal({onEquipmentSelected, setShowModal}: Props) {
    const {t} = useTranslation();
    const {selectedEquipment} = useConfigManager();

    function selectEquipment(equipment: Equipment) {
        onEquipmentSelected(equipment);
        setShowModal(false);
    }

    function renderEquipment(equipment: Equipment) {
        return (<div className={'flex-container expand full-width center'}>
            <Button
                className={'half-width'}
                text={equipment.name}
                onClick={() => selectEquipment(equipment)}
            />
        </div>)
    }

    return (<BasicModal setShowModal={setShowModal}>
        <BasicModalHeader>
            <h1>{t('equipment_modal_title')}</h1>
        </BasicModalHeader>
        <BasicModalContent>
            {hasData(selectedEquipment) && (<List component="nav" style={{maxHeight: '50vh', overflow: 'auto'}}>
                {selectedEquipment.map(equipment => {
                    return (<ListItem
                        text={equipment.name}
                        children={[renderEquipment(equipment)]}
                    />);
                })}
            </List>)}
        </BasicModalContent>
        <BasicModalFooter>
            <Button
                type={'button'}
                text={t('cancel')}
                onClick={() => setShowModal(false)}
            />
        </BasicModalFooter>
    </BasicModal>)
}
