import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useConfigManager} from "../../../utils/hooks/data/providers/config";
import {hasData} from "../../../utils/toolbox";
import {Select} from "../../tmp/select";

export function MainOperatorSelect() {
    const {t} = useTranslation();
    const {
        availableOperators, selectedMainOperator, setSelectedMainOperator
    } = useConfigManager();
    const [operators, setOperators] = useState([]);

    useEffect(() => {
        setOperators(selectedMainOperator ? [...availableOperators, selectedMainOperator] : availableOperators);
    }, [selectedMainOperator, availableOperators]);

    if (!hasData(availableOperators) && !selectedMainOperator) {
        return null;
    }

    return <Select
        label={t('select_operators_main_operator')}
        setSelectedData={setSelectedMainOperator}
        selectedData={selectedMainOperator}
        data={operators}
    />;
}
