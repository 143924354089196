import moment from 'moment';
import {extendMoment} from 'moment-range';
import Moment from 'react-moment';

// Common translations
// @ts-ignore FIXME
import commonEn from './locales/common_en.json';

export const DEFAULT_LANGUAGE = 'en';

// Available languages
const locales = {
    en: {common: commonEn},
};

export const configureI18n = lang => {
    return {
        resources: locales, lng: lang, fallbackLng: DEFAULT_LANGUAGE, defaultNS: 'common', keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }, react: {
            wait: false, bindI18n: 'languageChanged loaded', bindStore: 'added removed', nsMode: 'common'
        }
    };
};

export const globalMoment = extendMoment(moment);

// TODO: Install moment-locales-webpack-plugin
export const configureMoment = locale => {
    globalMoment.locale(locale);
    Moment.globalMoment = globalMoment;
    Moment.globalLocale = locale;
    Moment.globalFormat = 'L';
};

