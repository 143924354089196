import axios from 'axios';
import qs from 'qs';
import {HTTP} from "../../constants/enums/http";
import {URL_SERVICES_COMMON_AUTH} from "../../constants/urls";
import {sleep} from "../../utils/toolbox";
import api from "../api";

const URL_TOKEN = URL_SERVICES_COMMON_AUTH + 'oauth/token';
const MAX_RETRY = 5;
const MAX_DELAY = 2000;

function handleError(e: any, url: string, method: HTTP) {
    const error = e.response;
    if (error) {
        console.error(`Request failed with status ${error.status} {
			url : ${url}
			vrb : ${method}
			err : ${error.data.error}
			msg : ${error.data.message}
			tms : ${error.data.timestamp}
		}`);
    } else {
        console.error(e);
    }
}

async function request(body: unknown) {
    let count = 0;
    while (count < MAX_RETRY) {
        try {
            const response = await axios.post(URL_TOKEN, qs.stringify(body));
            return response.data;
        } catch (error) {
            handleError(error, URL_TOKEN, HTTP.POST);
            if ([500, 502, 503].some(code => error.message.includes(code) && count < MAX_RETRY)) {
                count++
                await sleep(MAX_DELAY)
            } else throw error
        }
    }
}

type Tokens = [access_token: string, refresh_token: string];

export default {

    /**
     * Authenticate user to the services.
     * @param username
     * @param password
     * @return {Promise<(*)[]>} [accessToken, refreshToken]
     */
    async authenticate(username: string, password: string): Promise<Tokens> {
        const json = await request({
            client_id: api.clientId, grant_type: 'password', username: username, password: password
        });
        return [json.access_token, json.refresh_token];
    },

    /**
     * Refresh tokens of the user.
     * @param refreshToken
     * @return {Promise<(*)[]>} [accessToken, refreshToken]
     */
    async refreshAccessToken(refreshToken: string): Promise<Tokens> {
        const json = await request({
            client_id: api.clientId, grant_type: 'refresh_token', refresh_token: refreshToken
        });
        return [json.access_token, json.refresh_token];
    },
}
