import {Button} from "@boschung/common-react-components";
import {DateTimePicker} from "@mui/lab";
import {Box, Checkbox, FormControlLabel, TextField} from "@mui/material";
import moment from "moment";
import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {MUIStyle} from "../../../../../styles/mui";
import {Intervention} from "../../../../../types/beemove-intervention";
import {InterventionSchedule} from "../../../../../types/intervention-simulator";
import {DATE_FORMAT, SCHEDULER_MAX_FIXED_MS, SCHEDULER_MIN_FIXED_MS} from "../../../../constants/const";
import {useBlueprintManager} from "../../../../utils/hooks/data/providers/blueprint";
import {useSimulatorManager} from "../../../../utils/hooks/data/providers/simulator";
import {isNullOrUndefined} from "../../../../utils/toolbox";
import {RadioGroup} from "../../../tmp/radio-group";
import {Select} from "../../../tmp/select";
import {InterventionsTable} from "../interventions/interventions-table";
import {SchedulesTable} from "../schedules/schedules-table";

import {CronModal} from "./modal/cron-modal";

// Style
import './style.scss';

type CronType = 'live' | 'cron';

export function SchedulerForm() {
    const {t} = useTranslation();
    const {
        blueprints, selectedBlueprint, setSelectedBlueprint
    } = useBlueprintManager();
    const {
        startIntervention
    } = useSimulatorManager();

    const [cronType, setCronType] = useState<CronType>('live');

    // Shared values
    const [runningType, setRunningType] = useState<InterventionSchedule['runningType']>('real');
    const [fixedRate, setFixedRate] = useState<InterventionSchedule['positionFixedRateMs']>(SCHEDULER_MIN_FIXED_MS);
    const [startDate, setStartDate] = useState<Intervention['startDateTime']>(new Date().toISOString());

    // Cron values
    const [cronValue, setCronValue] = useState<InterventionSchedule['cron']>('');
    const [enabled, setEnabled] = useState<InterventionSchedule['enable']>(true);
    const {
        scheduleIntervention, updateSchedule
    } = useSimulatorManager();
    const [showModal, setShowModal] = useState(false);

    function setupLiveIntervention() {
        if (!isNullOrUndefined(selectedBlueprint)) {
            startIntervention(selectedBlueprint.id, runningType, fixedRate, moment(startDate).toISOString());
        }
    }

    function quartzToSpringCron(cron) {
        let springCron = '';
        const parts = cron.split(" ");
        for (let i = 0; i < 6; i++) {
            springCron += parts[i] + " ";
        }
        return springCron;
    }

    function setupCronIntervention() {
        if (!isNullOrUndefined(selectedBlueprint)) {
            scheduleIntervention({
                cron: quartzToSpringCron(cronValue),
                enable: enabled,
                interventionId: selectedBlueprint.id,
                runningType: runningType,
                positionFixedRateMs: fixedRate,
            });
        }
    }

    function renderLiveButton() {
        return (<Button
            className={'full-width'}
            disabled={false} // TODO
            onClick={setupLiveIntervention}
            text={t('scheduler_save_live')}
        />);
    }

    function renderCronButton() {
        return (<Button
            className={'full-width'}
            disabled={false} // TODO
            onClick={setupCronIntervention}
            text={t('scheduler_save_schedule')}
        />);
    }

    return (<Box className={'scheduler-container'} sx={MUIStyle}>
        <div className={'scheduler-left'}>
            <div className={'flex-container shrink'}>
                <Select
                    selectedData={selectedBlueprint}
                    setSelectedData={setSelectedBlueprint}
                    data={blueprints}
                />
            </div>
            <div className={'flex-container__column expand center'}>
                <div className={'scheduler-subform'}>
                    <RadioGroup
                        className={'center'}
                        value={runningType}
                        onChange={e => setRunningType(e.target.value)}
                        options={['real', 'fixed_rate']}
                        orientation={'horizontal'}
                        label={t('scheduler_running_type')}
                        name={'runningType'}
                    />
                    {runningType === "fixed_rate" && (<TextField
                        label={t('scheduler_fixed_rate')}
                        type={'number'}
                        variant={'standard'}
                        onChange={e => setFixedRate(Number(e.target.value))}
                        aria-valuemin={SCHEDULER_MIN_FIXED_MS}
                        aria-valuemax={SCHEDULER_MAX_FIXED_MS}
                    />)}
                    <DateTimePicker
                        disableFuture
                        label={t('scheduler_start_date')}
                        inputFormat={DATE_FORMAT}
                        onChange={setStartDate}
                        value={startDate}
                        renderInput={(params) => <TextField variant={'standard'} {...params} />}
                    />
                </div>
                <div className={'scheduler-subform'}>
                    <RadioGroup
                        className={'center'}
                        orientation={'horizontal'}
                        value={cronType}
                        onChange={e => setCronType(e.target.value)}
                        options={['live', 'cron']}
                        label={t('scheduler_cron_type')}
                        name={'cronType'}
                    />
                    {cronType === 'cron' && (<div className={'flex-container__column expand center'}>
                        <FormControlLabel
                            control={<Checkbox
                                defaultChecked
                                onChange={e => setEnabled(e.target.checked)}
                                value={enabled}
                            />}
                            label={t('scheduler_cron_active')}
                        />
                        <TextField
                            fullWidth
                            variant={'standard'}
                            label={t('scheduler_cron')}
                            onClick={() => setShowModal(true)}
                            value={cronValue && quartzToSpringCron(cronValue)}
                        />
                        {showModal && <CronModal
                            setShowModal={setShowModal}
                            cronValue={cronValue}
                            setCronValue={setCronValue}
                        />}
                    </div>)}
                </div>
                {cronType === 'live' && renderLiveButton()}
                {cronType === 'cron' && renderCronButton()}
            </div>
        </div>
        <div className={'right'}>
            <InterventionsTable/>
            <SchedulesTable/>
        </div>
    </Box>);
}

export default withTranslation()(SchedulerForm);
