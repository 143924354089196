// @ts-ignore
import {default as BoschungColors} from './_colors.scss';

export const MUITableStyle = {
    '& .MuiPaper-root': {
        backgroundColor: BoschungColors.boschungSecondaryColor, '& .MuiTableCell-root': {
            backgroundColor: BoschungColors.boschungSecondaryDarkerColor,
            borderBottomColor: BoschungColors.boschungSecondaryDarkColor,
            color: BoschungColors.boschungMainColor,
        }, '& .MuiTableCell-body': {
            backgroundColor: BoschungColors.boschungSecondaryColor, color: 'white',
        },
    },
};

export const MUIStyle = {
    '& .Mui-focused': {
        color: 'inherit',
    }, '& .MuiFormControl-root': {
        flex: 1, height: 'min-content'
    }, '& .MuiTypography-root': {
        color: 'white', fontSize: '1.25em',
    }, '& .MuiInputLabel-shrink': {
        left: '-12px',
    }, '& .MuiFormLabel-root': {
        color: 'white', fontSize: '1.25em',
    }, '& .MuiInputLabel-root': {
        color: 'white', fontSize: '1.25em',
    }, '& .MuiInput-root': {
        marginTop: '1.25em', color: 'white'
    }, '& .MuiAutocomplete-tag': {
        color: 'white',
        border: '1px solid #ffcc00',
        borderRadius: 2,
        backgroundColor: 'transparent',
        '& .MuiSvgIcon-root': {
            border: 0, backgroundColor: 'transparent', color: BoschungColors.boschungMainColor
        }
    }, '& .MuiButtonBase-root': {
        backgroundColor: 'transparent',
    }, '& .MuiSvgIcon-root': {
        backgroundColor: 'transparent', color: 'white',
    }, '& .MuiInput-underline': {
        '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 1)'
        }, '&:after': {
            borderBottom: `1px solid rgba(255, 180, 0, 1)`
        }, '&:hover:before': {
            borderBottom: `1px solid rgba(255, 200, 00, 1)`
        }
    }, '& .MuiRadio-root': {
        '& .MuiSvgIcon-root': {
            color: BoschungColors.boschungMainColor
        },
    }
};
