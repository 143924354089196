import React from 'react';
import {useTranslation} from "react-i18next";
import {DenseTable} from "./dense-table";

export function PropertyTable({item, ignored, translations}) {
    const {t} = useTranslation();
    return (<DenseTable
        headers={['key', 'value']}
        translations={{
            'key': t('table_property_header_key'), 'value': t('table_property_header_value'),
        }}
        items={Object.keys(item)
            .filter(property => !ignored.has(property))
            .map(property => {
                function _processPropertyValue(value) {
                    switch (typeof value) {
                        case "boolean":
                        case "number":
                        case "bigint":
                            return t(String(value));
                        case "undefined":
                            return t('none');
                        default:
                            return value;
                    }
                }

                return {
                    'key': translations[property], 'value': _processPropertyValue(item[property])
                }
            })}
    />);
}
