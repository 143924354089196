import {Box} from "@mui/material";
import React from 'react';
import {useTranslation} from "react-i18next";
import {MUITableStyle} from "../../../../../../styles/mui";
import {Intervention} from "../../../../../../types/beemove-intervention";
import {useSimulatorManager} from "../../../../../utils/hooks/data/providers/simulator";
import {BasicModal, BasicModalContent, BasicModalHeader} from "../../../../modal/basic-modal";
import {PropertyTable} from "../../../../tables/property-table";

interface Props {
    clickedId: Intervention['id'],
    setShowModal: React.Dispatch<boolean>
}

export function InterventionModal({setShowModal, clickedId}: Props) {
    const {t} = useTranslation();
    const {interventions} = useSimulatorManager();
    const intervention = interventions.find(i => i.id === clickedId);
    const ignored = new Set(['equipments', 'vehicle', 'summary', 'instantaneous', 'isSimulated']);
    const translations: Record<string, string> = {
        'id': t('intervention_table_header_id'),
        'centerId': t('intervention_table_header_center_id'),
        'driverId': t('intervention_table_header_operator_main_id'),
        'coDriverId': t('intervention_table_header_operator_secondary_id'),
        'operatorIds': t('intervention_table_header_operator_ids'),
        'vehicleId': t('intervention_table_header_vehicle_id'),
        'startDateTime': t('intervention_table_header_date_start'),
        'endDateTime': t('intervention_table_header_date_end'),
        'state': t('intervention_table_header_state'),
        'source': t('intervention_table_header_source')
    };

    return (// @ts-ignore FIXME
        <BasicModal setShowModal={setShowModal}>
            <BasicModalHeader>
                <h1>{clickedId}</h1>
            </BasicModalHeader>
            <BasicModalContent>
                <Box sx={MUITableStyle}>
                    <PropertyTable
                        translations={translations}
                        ignored={ignored}
                        item={intervention}
                    />
                </Box>
            </BasicModalContent>
        </BasicModal>);
}
