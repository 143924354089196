import {Button} from '@boschung/common-react-components';
import {
    Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
} from "@mui/material";
import PropTypes, {array, func, object} from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isNullOrUndefined} from "../../utils/toolbox";

export function DenseTable(props) {
    const {t} = useTranslation();
    const {
        className, translations, items, actions, onRowClick, itemCountPerPage
    } = props;

    const ignored = new Set(props.ignored);
    const headers = props.headers.filter(header => !ignored.has(header));

    // Paginate
    const [offset, setOffset] = useState(0);
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if (isNullOrUndefined(items)) return;
        setPaginatedRows(items.slice(offset, offset + itemCountPerPage));
        setPageCount(Math.ceil(items.length / itemCountPerPage));
    }, [offset, items]);

    function renderHeader() {
        return (<TableRow>
            {headers.map(header => (<TableCell align={"center"}>
                {translations[header] || header}
            </TableCell>))}
            {!isNullOrUndefined(actions) && (<TableCell align={"center"}>
                {t('table_actions')}
            </TableCell>)}
        </TableRow>);
    }

    function renderBody() {
        return (Array.isArray(paginatedRows) && paginatedRows.length > 0 ? paginatedRows.map((item, index) => (
            <TableRow key={index}>
                {headers.map(header => (<TableCell align="center" onClick={() => onRowClick && onRowClick(item)}>
                    {item[header]}
                </TableCell>))}
                {!isNullOrUndefined(actions) && (<TableCell align="center">
                    <div className={'flex-container center expand'}>
                        {actions(item).map(action => (<Button
                            buttonSize={'sm'}
                            iconName={action.iconName}
                            onClick={action.callback}
                        />))}
                    </div>
                </TableCell>)}
            </TableRow>)) : (<TableRow>
            <TableCell colSpan={99}>
                <div className={"flex-container center"}>
                    {t('nothing_found')}
                </div>
            </TableCell>
        </TableRow>));
    }

    function renderFooter() {
        return (items && items.length > itemCountPerPage && (<TableRow>
            <TableCell colSpan={headers.length + (isNullOrUndefined(actions) ? 0 : 1)}>
                <div className={"flex-container center"}>
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            onChange={(_, value) => setOffset((value - 1) * itemCountPerPage)}
                        />
                    </Stack>
                </div>
            </TableCell>
        </TableRow>));
    }

    // Render
    return (<TableContainer component={Paper}>
        <Table
            className={className}
            style={{tableLayout: items && items.length > 0 ? "fixed" : "auto"}}
            size="small"
        >
            <TableHead>
                {renderHeader()}
            </TableHead>
            <TableBody>
                {renderBody()}
            </TableBody>
            <TableFooter>
                {renderFooter()}
            </TableFooter>
        </Table>
    </TableContainer>)
}

DenseTable.defaultProps = {
    ignored: [], translations: [], onRowClick: null, itemCountPerPage: 10
}

DenseTable.propTypes = {
    itemCountPerPage: PropTypes.number,
    className: PropTypes.string,
    items: object.isRequired,
    headers: array.isRequired,
    ignored: array,
    translations: object,
    onRowClick: func,
    actions: PropTypes.func
};
