import i18n from 'i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import React, {PropsWithChildren} from 'react';
import {I18nextProvider, initReactI18next} from 'react-i18next';

import {configureI18n, configureMoment, DEFAULT_LANGUAGE} from './config';

interface Props {
    lang: 'en' | 'fr' | 'de'
}

export const I18nManager = ({lang, children}: PropsWithChildren<Props>) => {
    const locale = lang ? lang : DEFAULT_LANGUAGE;

    // Configure i18n translations
    const i18nConfig = configureI18n(locale);
    i18n
        .use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        // @ts-ignore
        .init(i18nConfig);

    // Configure i18n Date format (Global Moment instance config)
    configureMoment(locale);

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
